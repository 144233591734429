import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import produce from 'immer'
import _ from 'lodash'
import {
  ChevronDown,
  ChevronRight,
  Pencil,
  PlusIcon,
  Trash,
} from 'lucide-react'
import pluralize from 'pluralize'

import { WorkspacePermBundle } from 'models/perms'
import {
  bulkUpdateRolePerms,
  createWorkspaceRole,
  EditDiffs,
  getWorkspaceRoleConfigs,
  getWorkspaceRoleConfigsInternalAdmin,
  getWorkspaceRoles,
  getWorkspaceRolesInternalAdmin,
  RoleConfig,
  updateWorkspaceRole,
  WorkspaceRole,
  WorkspaceRoleConfig,
} from 'models/roles'
import { Workspace } from 'models/workspace'
import { instanceOfBetaPermission } from 'openapi/models/BetaPermission'
import { DefaultRole, instanceOfDefaultRole } from 'openapi/models/DefaultRole'
import { DefaultVisibility } from 'openapi/models/DefaultVisibility'
import { instanceOfInternalOnlyPermission } from 'openapi/models/InternalOnlyPermission'
import { PermissionBundleId } from 'openapi/models/PermissionBundleId'
import { PermissionCategory } from 'openapi/models/PermissionCategory'
import { instanceOfSensitiveUserPermission } from 'openapi/models/SensitiveUserPermission'
import { Maybe } from 'types'

import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { useAuthUser } from 'components/common/auth-context'
import CreateRoleModal from 'components/settings/roles/create-role-modal'
import BetaPermBadge from 'components/settings/workspace/permissions/beta-perm-badge'
import { Button } from 'components/ui/button'
import { Checkbox } from 'components/ui/checkbox'
import { DataTable } from 'components/ui/data-table/data-table'
import { Dialog } from 'components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import Icon from 'components/ui/icon/icon'
import AlertIcon from 'components/ui/icons/alert-icon'
import SearchInput from 'components/ui/search-input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip'

import WorkspaceDeleteRoleDialog from './workspace-delete-role-dialog'
import WorkspaceUpdateRoleDialog from './workspace-update-role-dialog'

/** Categories will be displayed in this order */
export const PERMISSION_CATEGORIES: PermissionCategory[] = [
  PermissionCategory.ASSISTANT,
  PermissionCategory.VAULT,
  PermissionCategory.VAULT_WORKFLOWS,
  PermissionCategory.LIBRARY,
  PermissionCategory.CLIENT_MATTERS,
  PermissionCategory.ADD_INS,
  PermissionCategory.INTEGRATIONS,
  PermissionCategory.HISTORY,
  PermissionCategory.ANALYTICS,
  PermissionCategory.TAX_KNOWLEDGE_SOURCES,
  PermissionCategory.CASE_LAW_KNOWLEDGE_SOURCES,
  PermissionCategory.WORKFLOWS,
  PermissionCategory.BASE_USER_DEFAULTS,
  PermissionCategory.USER_MANAGEMENT,
  PermissionCategory.API,
  PermissionCategory.ADMIN_ONLY,
  PermissionCategory.HARVEY_EMPLOYEES_ONLY,
  PermissionCategory.INTERNAL_TESTING,
  PermissionCategory.ASSIGNED_BY_USER_OPS_ONLY,
]

/**
 * Collapsing some categories with a lot of permissions or are more sensitive.
 * This also aids with initial render performance and general snappiness.
 */
const DEFAULT_COLLAPSED_CATEGORIES: PermissionCategory[] = [
  PermissionCategory.TAX_KNOWLEDGE_SOURCES,
  PermissionCategory.CASE_LAW_KNOWLEDGE_SOURCES,
  PermissionCategory.WORKFLOWS,
  PermissionCategory.BASE_USER_DEFAULTS,
  PermissionCategory.USER_MANAGEMENT,
  PermissionCategory.API,
  PermissionCategory.ADMIN_ONLY,
  PermissionCategory.HARVEY_EMPLOYEES_ONLY,
  PermissionCategory.INTERNAL_TESTING,
  PermissionCategory.ASSIGNED_BY_USER_OPS_ONLY,
]

const VISIBILITY_METADATA = {
  [DefaultVisibility.MANAGE]: {
    label: 'Manage',
    description: 'Admins can add, modify, or remove this permission for users.',
  },
  [DefaultVisibility.VIEW]: {
    label: 'View',
    description: 'Admins can view this permission but cannot make changes.',
  },
  [DefaultVisibility.HIDE]: {
    label: 'No access',
    description: 'Admins cannot view this permission.',
  },
  [DefaultVisibility.INTERNAL_ONLY]: {
    label: 'Internal only',
    description: 'This permission can only be assigned by internal admins.',
  },
} as const

type CategoryRowProps = {
  type: 'category'
  name: PermissionCategory
  children: PermBundleRowProps[]
}

type PermBundleRowProps = {
  type: 'permBundle'
  permBundle: WorkspacePermBundle
  visibility: DefaultVisibility
  roleConfigs: Partial<
    Record<
      string,
      RoleConfig & {
        checkedState: boolean | 'indeterminate'
        savedCheckedState: boolean | 'indeterminate'
      }
    >
  >
  children?: PermRowProps[]
}

type PermRowProps = {
  type: 'perm'
  permId: string
  enabled: boolean
  savedEnabled: boolean
  isLast?: boolean
  children?: never
}

type RowProps = CategoryRowProps | PermBundleRowProps | PermRowProps

const getRowId = (row: RowProps) => {
  switch (row.type) {
    case 'category':
      return `category:${row.name}`
    case 'perm':
      return `perm:${row.permId}`
    default:
      return `bundle:${row.permBundle.id}`
  }
}

const getInitialEditDiffs = (): EditDiffs => ({
  diffsByRolePk: {},
  enabledPermIds: new Set<string>(),
  disabledPermIds: new Set<string>(),
  visibilityByPermBundleId: {},
})

const getNumDiffs = (editDiffs: EditDiffs) => {
  const numRoleDiffs = Object.values(editDiffs.diffsByRolePk).reduce(
    (acc, roleDiffs) => {
      if (!roleDiffs) return acc
      return (
        acc +
        roleDiffs.enabledPermBundleIds.size +
        roleDiffs.disabledPermBundleIds.size
      )
    },
    0
  )
  return (
    numRoleDiffs +
    editDiffs.enabledPermIds.size +
    editDiffs.disabledPermIds.size +
    Object.values(editDiffs.visibilityByPermBundleId).length
  )
}

const normalizeText = (text: Maybe<string>) => (text || '').toLowerCase().trim()

const matchesSearch = (text: Maybe<string>, searchQuery: string) =>
  normalizeText(text).includes(normalizeText(searchQuery))

const rowMatchesSearch = (row: RowProps, searchQuery: string): boolean => {
  if (!searchQuery) return false

  switch (row.type) {
    case 'category':
      return matchesSearch(row.name, searchQuery)
    case 'permBundle': {
      const { permBundle } = row
      return (
        matchesSearch(permBundle.name, searchQuery) ||
        matchesSearch(permBundle.description, searchQuery)
      )
    }
    case 'perm':
      return matchesSearch(row.permId, searchQuery)
  }
}

// Handle search by expanding/collapsing matching rows instead of filtering out
const getSearchExpandedState = (
  searchQuery: string,
  rows: RowProps[]
): ExpandedState => {
  if (!searchQuery) {
    return getDefaultExpansionState()
  }

  const expandedState: ExpandedState = {}
  const seen = new Set<string>()

  // Process a single row and its descendants recursively
  const processRow = (row: RowProps, ancestorIds: string[] = []) => {
    const rowId = getRowId(row)
    if (seen.has(rowId)) return
    seen.add(rowId)

    if (rowMatchesSearch(row, searchQuery)) {
      expandedState[rowId] = true
      // Expand all ancestors when there's a match
      ancestorIds.forEach((id) => {
        expandedState[id] = true
      })
    }

    const children = 'children' in row ? row.children || [] : []
    children.forEach((child) => processRow(child, [...ancestorIds, rowId]))
  }

  rows.forEach((row) => processRow(row))
  return expandedState
}

const getDefaultExpansionState = (): ExpandedState =>
  Object.fromEntries(
    PERMISSION_CATEGORIES.map((category) => [
      getRowId({ type: 'category', name: category, children: [] }),
      !DEFAULT_COLLAPSED_CATEGORIES.includes(category),
    ])
  )

const Expander = <TData,>({ row }: { row: Row<TData> }) => (
  <div className="pl-2">
    <Button
      variant="ghost"
      size="xxsIcon"
      onClick={(e) => {
        e.stopPropagation()
        row.toggleExpanded()
      }}
      className="size-3 rounded"
    >
      <ChevronRight
        className={cn('size-3 transition-transform', {
          'rotate-90': row.getIsExpanded(),
        })}
      />
    </Button>
  </div>
)

/**
 * Need memoization here to isolate re-renders because this table has several
 * hundreds of checkboxes and is not virtualized. If this continues to scale
 * quickly, then we should reconsider virtualization.
 */
const RolePermBundleCheckbox = React.memo(
  function RolePermBundleCheckbox({
    isClientAdminView,
    disabled,
    disabledReason,
    role,
    permBundle,
    missingPerms,
    savedCheckedState,
    checkedState,
    onPermissionChange,
  }: {
    isClientAdminView: boolean
    disabled: boolean
    disabledReason?: string
    role: WorkspaceRole
    permBundle: WorkspacePermBundle
    missingPerms?: string[]
    savedCheckedState: boolean | 'indeterminate'
    checkedState: boolean | 'indeterminate'
    onPermissionChange: (
      role: WorkspaceRole,
      permBundle: WorkspacePermBundle,
      savedCheckedState: boolean | 'indeterminate',
      checked: boolean | 'indeterminate'
    ) => void
  }) {
    const handleCheckedChange = useCallback(
      (checked: boolean | 'indeterminate') => {
        onPermissionChange(role, permBundle, savedCheckedState, checked)
      },
      [role, permBundle, savedCheckedState, onPermissionChange]
    )

    let tooltip: string | undefined
    if (disabledReason) {
      tooltip = disabledReason
    } else if (
      !isClientAdminView &&
      checkedState === 'indeterminate' &&
      missingPerms?.length
    ) {
      tooltip = `Missing: ${missingPerms.join(', ')}`
    }

    return (
      <Button
        variant="ghost"
        className="size-auto p-0"
        size="smIcon"
        tooltip={tooltip}
      >
        <Checkbox
          checked={checkedState}
          isIndeterminate={
            !isClientAdminView && checkedState === 'indeterminate'
          }
          disabled={disabled}
          onCheckedChange={handleCheckedChange}
        />
      </Button>
    )
  },
  (prevProps, nextProps) => {
    return (
      prevProps.isClientAdminView === nextProps.isClientAdminView &&
      prevProps.checkedState === nextProps.checkedState &&
      prevProps.disabled === nextProps.disabled &&
      prevProps.disabledReason === nextProps.disabledReason &&
      _.isEqual(prevProps.missingPerms, nextProps.missingPerms) &&
      prevProps.role === nextProps.role &&
      prevProps.permBundle === nextProps.permBundle &&
      prevProps.savedCheckedState === nextProps.savedCheckedState &&
      prevProps.onPermissionChange === nextProps.onPermissionChange
    )
  }
)

const RoleMenu = ({
  role,
  setUpdatingRole,
  setConfirmingDeleteRole,
}: {
  role: WorkspaceRole
  setUpdatingRole: (role: WorkspaceRole) => void
  setConfirmingDeleteRole: (role: WorkspaceRole) => void
}) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild className="shrink-0">
      <Button variant="ghost" size="sm" className="h-4 p-0.5">
        <ChevronDown className="size-3" />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="end" className="w-48">
      <DropdownMenuItem
        className="flex items-center justify-between gap-2"
        onClick={() => setUpdatingRole(role)}
      >
        Edit role
        <Icon icon={Pencil} className="size-3" />
      </DropdownMenuItem>
      <DropdownMenuItem
        className="flex items-center justify-between gap-2 text-destructive transition-colors hover:text-destructive focus:text-destructive"
        onClick={() => setConfirmingDeleteRole(role)}
      >
        Delete role
        <Icon icon={Trash} className="size-3" />
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
)

const WorkspaceRolesTableV2: React.FC<{
  workspace: Workspace
  isClientAdminView?: boolean
}> = ({ workspace, isClientAdminView = false }) => {
  const userInfo = useAuthUser()
  const [filter, setFilter] = useState<string>('')
  const [sorting, setSorting] = useState<SortingState>([])
  const [expanded, setExpanded] = useState<ExpandedState>(
    getDefaultExpansionState()
  )
  const [workspaceRoleConfigs, setWorkspaceRoleConfigs] = useState<
    WorkspaceRoleConfig[]
  >([])
  const [roles, setRoles] = useState<WorkspaceRole[]>([])
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] =
    useState<boolean>(false)
  const [updatingRole, setUpdatingRole] = useState<WorkspaceRole>()
  const [confirmingDeleteRole, setConfirmingDeleteRole] =
    useState<WorkspaceRole>()
  const [isLoading, setIsLoading] = useState(true)

  const [isEditing, setIsEditing] = useState(false)
  const [editDiffs, setEditDiffs] = useState<EditDiffs>(getInitialEditDiffs())

  const canEdit = userInfo.IsInternalAdminWriter && !isClientAdminView // TODO: Eventually add SSP write perm

  // Violations are checkboxes where the role is missing some permissions in the bundle
  const violations: RoleConfig[] = useMemo(() => {
    return workspaceRoleConfigs
      .filter((row) => PERMISSION_CATEGORIES.includes(row.permBundle.category))
      .reduce<RoleConfig[]>((acc, row) => {
        return [
          ...acc,
          ...row.roleConfigs.filter(
            (rc) =>
              rc.missingPerms.length > 0 &&
              // Note: Not great to keep this checked logic here - should return directly from API
              rc.missingPerms.length < row.permBundle.enabledPerms.length
          ),
        ]
      }, [])
  }, [workspaceRoleConfigs])

  const fetchRoles = useCallback(async () => {
    try {
      setIsLoading(true)
      const getRoleConfigs = isClientAdminView
        ? getWorkspaceRoleConfigs
        : getWorkspaceRoleConfigsInternalAdmin
      const roleConfigs = await getRoleConfigs(workspace.id)
      setWorkspaceRoleConfigs(roleConfigs)

      const getRoles = isClientAdminView
        ? getWorkspaceRoles
        : getWorkspaceRolesInternalAdmin
      const roles = await getRoles(workspace.id)
      setRoles(
        roles.filter((role) => {
          if (isClientAdminView) {
            const roleSlug = role.roleId.slice(workspace.slug.length + 1)
            if (roleSlug === DefaultRole.API) {
              return false
            }
          }
          return !role.deletedAt // TODO (ken): Move filter to API call
        })
      )
    } catch (error) {
      displayErrorMessage('Failed to fetch workspace roles')
    } finally {
      setIsLoading(false)
    }
  }, [workspace.id, workspace.slug, isClientAdminView])

  useEffect(() => {
    void fetchRoles()
  }, [fetchRoles])

  const handleCreateRole = async (
    name: string,
    description: string,
    withBasePerms: boolean
  ) => {
    await createWorkspaceRole(
      workspace.id,
      {
        name,
        desc: description,
      },
      withBasePerms
    )
    setIsCreateRoleModalOpen(false)
    void fetchRoles()
  }

  const handleUpdateRole = async ({
    rolePk,
    name,
    description,
  }: {
    rolePk: string
    name?: string
    description?: string
  }) => {
    try {
      const updatedRole = await updateWorkspaceRole(rolePk, {
        name: name ?? '',
        desc: description ?? '',
      })
      setRoles((roles) =>
        roles.map((role) => (role.rolePk === rolePk ? updatedRole : role))
      )
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('Failed to update role')
      }
    }
  }

  const handleDeleteRole = async () => {
    setConfirmingDeleteRole(undefined)
    void fetchRoles()
  }

  const handleSaveEdits = async () => {
    try {
      await bulkUpdateRolePerms(workspace.id, editDiffs)
      setIsEditing(false)
      await fetchRoles()
      setEditDiffs(getInitialEditDiffs())
    } catch (error) {
      if (error instanceof Error) {
        displayErrorMessage(error.message)
      } else {
        displayErrorMessage('Failed to save changes')
      }
    }
  }

  const handlePermissionChange = useCallback(
    (
      role: WorkspaceRole,
      permBundle: WorkspacePermBundle,
      savedCheckedState: boolean | 'indeterminate',
      checked: boolean | 'indeterminate'
      // eslint-disable-next-line max-params
    ) => {
      if (checked === 'indeterminate') checked = true
      setEditDiffs((editDiffs) =>
        produce(editDiffs, (draft) => {
          let roleDiffs = draft.diffsByRolePk[role.rolePk]
          if (!roleDiffs) {
            roleDiffs = {
              enabledPermBundleIds: new Set<PermissionBundleId>(),
              disabledPermBundleIds: new Set<PermissionBundleId>(),
            }
            draft.diffsByRolePk[role.rolePk] = roleDiffs
          }
          if (checked) {
            roleDiffs.disabledPermBundleIds.delete(permBundle.id)
            if (checked !== savedCheckedState) {
              roleDiffs.enabledPermBundleIds.add(permBundle.id)
            }
          } else {
            roleDiffs.enabledPermBundleIds.delete(permBundle.id)
            if (checked !== savedCheckedState) {
              roleDiffs.disabledPermBundleIds.add(permBundle.id)
            }
          }
        })
      )
    },
    []
  )

  const columns = useMemo<ColumnDef<RowProps>[]>(() => {
    const allColumns: Array<
      ColumnDef<RowProps> & {
        hide?: boolean
      }
    > = [
      {
        accessorKey: 'permBundle',
        header: () => (
          <div className="pl-4 text-xs font-medium">Permission</div>
        ),
        cell: ({ row }) => {
          if (row.original.type === 'category') {
            return (
              <div
                className={cn(
                  '-mx-4 -my-2 flex min-w-[400px] items-center gap-2 bg-secondary p-2 text-sm',
                  isClientAdminView && 'min-w-[600px]'
                )}
              >
                <Expander row={row} />
                {row.original.name}
                {row.subRows.length ? (
                  <span className="text-sm text-muted">
                    {`(${row.subRows.length} ${pluralize(
                      'permission',
                      row.subRows.length
                    )})`}
                  </span>
                ) : null}
              </div>
            )
          }
          if (row.original.type === 'perm') {
            const { permId, enabled, savedEnabled } = row.original
            let disabled = !isEditing
            let disabledReason: string | undefined
            if (
              instanceOfSensitiveUserPermission(permId) &&
              !userInfo.IsInternalAdminWriter
            ) {
              disabled = true
              disabledReason =
                'Only internal super admins can grant sensitive permissions to roles.'
            }
            return (
              <div
                className={cn(
                  '-my-1 ml-16 flex items-center gap-4',
                  row.original.isLast && 'pb-3'
                )}
              >
                <Button
                  variant="ghost"
                  size="smIcon"
                  disabled={disabled}
                  tooltip={disabledReason}
                  className="size-auto p-0"
                >
                  <Checkbox
                    disabled={!isEditing || disabled}
                    checked={enabled}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setEditDiffs((editDiffs) =>
                          produce(editDiffs, (draft) => {
                            draft.disabledPermIds.delete(permId)
                            if (!savedEnabled) {
                              draft.enabledPermIds.add(permId)
                            }
                          })
                        )
                      } else {
                        setEditDiffs((editDiffs) =>
                          produce(editDiffs, (draft) => {
                            draft.enabledPermIds.delete(permId)
                            if (savedEnabled) {
                              draft.disabledPermIds.add(permId)
                            }
                          })
                        )
                      }
                    }}
                  />
                </Button>
                <div className="flex items-center gap-2">
                  <div className="text-sm">{row.original.permId}</div>
                  {instanceOfBetaPermission(row.original.permId) && (
                    <BetaPermBadge />
                  )}
                </div>
              </div>
            )
          }
          const permBundle = row.original.permBundle
          return (
            <div className="ml-4 flex items-start gap-2 bg-primary">
              {!isClientAdminView ? <Expander row={row} /> : <div />}
              <div className="w-full">
                <div className="flex items-center gap-2 text-sm font-medium">
                  {permBundle.name}
                  {permBundle.permissions.some((perm) =>
                    instanceOfBetaPermission(perm)
                  ) && <BetaPermBadge />}
                </div>
                <div className="text-sm text-muted">
                  {permBundle.description}
                </div>
              </div>
            </div>
          )
        },
      },
      {
        accessorKey: 'visibility',
        header: '',
        hide: !canEdit,
        cell: ({ row }) => {
          if (row.original.type !== 'permBundle') return null
          const permBundle = row.original.permBundle
          const visibility = row.original.visibility
          return (
            <Select
              disabled={
                !isEditing || visibility === DefaultVisibility.INTERNAL_ONLY
              }
              value={visibility}
              onValueChange={(value) => {
                setEditDiffs((editDiffs) =>
                  produce(editDiffs, (draft) => {
                    if (value === visibility) {
                      delete draft.visibilityByPermBundleId[permBundle.id]
                    } else {
                      draft.visibilityByPermBundleId[permBundle.id] =
                        value as DefaultVisibility
                    }
                  })
                )
              }}
            >
              <SelectTrigger className="h-8 w-32">
                <SelectValue placeholder="Select">
                  <span className="line-clamp-1 text-left text-sm">
                    {VISIBILITY_METADATA[visibility].label}
                  </span>
                </SelectValue>
              </SelectTrigger>
              <SelectContent className="w-72 p-2">
                {Object.values(DefaultVisibility)
                  .filter((v) => v !== DefaultVisibility.INTERNAL_ONLY) // Can't set to or from INTERNAL_ONLY
                  .map((visibility) => (
                    <SelectItem key={visibility} value={visibility}>
                      {VISIBILITY_METADATA[visibility].label}
                      <p className="text-wrap text-muted">
                        {VISIBILITY_METADATA[visibility].description}
                      </p>
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          )
        },
      },
      ...roles.map<ColumnDef<RowProps>>((role) => ({
        accessorFn: (row) => {
          if (row.type === 'category' || row.type === 'perm') {
            return null
          }
          return row.roleConfigs[role.rolePk]
        },
        id: role.rolePk,
        header: () => {
          const baseSlug = role.roleId.slice(workspace.slug.length + 1)
          return (
            <div className="flex w-24 items-center gap-0.5">
              <Tooltip>
                <TooltipTrigger asChild>
                  <span className="truncate text-xs font-medium">
                    {role.name}
                  </span>
                </TooltipTrigger>
                <TooltipContent side="bottom">{role.name}</TooltipContent>
              </Tooltip>

              {!instanceOfDefaultRole(baseSlug) && canEdit && (
                <RoleMenu
                  role={role}
                  setUpdatingRole={setUpdatingRole}
                  setConfirmingDeleteRole={setConfirmingDeleteRole}
                />
              )}
            </div>
          )
        },
        cell: ({ row }) => {
          if (row.original.type !== 'permBundle') return null
          const roleConfig = row.original.roleConfigs[role.rolePk]

          let disabled = !isEditing
          let disabledReason: string | undefined
          if (
            !workspace.vaultUserCountIsUnlimited &&
            row.original.permBundle.id === 'vault_access'
          ) {
            disabled = true
            disabledReason =
              'Vault access can only be granted to individual users by going to the User tab or User Management page.'
          } else if (
            row.original.permBundle.permissions.some((perm) =>
              instanceOfInternalOnlyPermission(perm)
            )
          ) {
            disabled = true
            disabledReason = 'Internal permissions cannot be granted to roles.'
          } else if (
            row.original.permBundle.permissions.some((perm) =>
              instanceOfSensitiveUserPermission(perm)
            ) &&
            !userInfo.IsInternalAdminWriter
          ) {
            disabled = true
            disabledReason =
              'Only internal super admins can grant sensitive permissions to roles.'
          }
          return (
            <RolePermBundleCheckbox
              isClientAdminView={isClientAdminView}
              checkedState={roleConfig?.checkedState ?? false}
              disabled={disabled}
              disabledReason={disabledReason}
              missingPerms={roleConfig?.missingPerms}
              role={role}
              permBundle={row.original.permBundle}
              savedCheckedState={roleConfig?.savedCheckedState ?? false}
              onPermissionChange={handlePermissionChange}
            />
          )
        },
        enableSorting: false,
      })),
    ]
    return allColumns.filter((column) => !column.hide)
  }, [
    roles,
    workspace.slug,
    isEditing,
    handlePermissionChange,
    workspace.vaultUserCountIsUnlimited,
    canEdit,
    userInfo.IsInternalAdminWriter,
    isClientAdminView,
  ])

  const categoryRows: CategoryRowProps[] = useMemo(
    () =>
      PERMISSION_CATEGORIES.map<CategoryRowProps>((category) => {
        return {
          type: 'category',
          name: category,
          children: workspaceRoleConfigs
            .filter((roleConfig) => roleConfig.permBundle.category === category)
            .map<PermBundleRowProps>((roleConfig) => {
              const permBundle = roleConfig.permBundle
              const savedVisibility = roleConfig.visibility
              const currVisibility = _.get(
                editDiffs.visibilityByPermBundleId,
                permBundle.id,
                savedVisibility || permBundle.defaultVisibility
              )

              // Only roles that have this perm bundle are returned, so create empty configs for all roles.
              const savedConfigsByPk = _.keyBy(roleConfig.roleConfigs, 'roleId')
              const roleConfigs: RoleConfig[] = roles.map<RoleConfig>(
                (role) => {
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                  if (!savedConfigsByPk[role.rolePk]) {
                    return {
                      roleId: role.rolePk,
                      permBundleId: permBundle.id,
                      permissions: [],
                      missingPerms: [],
                    }
                  }
                  return savedConfigsByPk[role.rolePk]
                }
              )

              return {
                type: 'permBundle',
                permBundle,
                visibility: currVisibility,
                roleConfigs: roleConfigs.reduce((acc, config) => {
                  let savedCheckedState: ComponentProps<
                    typeof Checkbox
                  >['checked'] = false

                  if (config.permissions.length === 0) {
                    savedCheckedState = false
                  } else if (config.missingPerms.length === 0) {
                    savedCheckedState = true
                  } else if (
                    config.missingPerms.length < permBundle.enabledPerms.length
                  ) {
                    savedCheckedState = 'indeterminate'
                  }

                  const roleDiffs = editDiffs.diffsByRolePk[config.roleId]
                  let checkedState = savedCheckedState
                  if (roleDiffs?.enabledPermBundleIds.has(permBundle.id)) {
                    checkedState = true
                  } else if (
                    roleDiffs?.disabledPermBundleIds.has(permBundle.id)
                  ) {
                    checkedState = false
                  }

                  return {
                    ...acc,
                    [config.roleId]: {
                      ...config,
                      checkedState,
                      savedCheckedState,
                    },
                  }
                }, {}),
                children: isClientAdminView
                  ? undefined
                  : roleConfig.permBundle.permissions.map<PermRowProps>(
                      (perm, index, array) => {
                        const savedCheckedState =
                          roleConfig.permBundle.enabledPerms.includes(perm)
                        const currCheckedState =
                          (savedCheckedState ||
                            editDiffs.enabledPermIds.has(perm)) &&
                          !editDiffs.disabledPermIds.has(perm)
                        return {
                          type: 'perm',
                          permId: perm,
                          enabled: currCheckedState,
                          savedEnabled: savedCheckedState,
                          isLast: index === array.length - 1,
                        }
                      }
                    ),
              }
            }),
        }
      }).filter((category) => category.children.length > 0), // Hide empty categories for now
    [
      roles,
      workspaceRoleConfigs,
      editDiffs.diffsByRolePk,
      editDiffs.visibilityByPermBundleId,
      editDiffs.disabledPermIds,
      editDiffs.enabledPermIds,
      isClientAdminView,
    ]
  )

  const table = useReactTable({
    data: categoryRows,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getRowId,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.children,
    state: {
      sorting,
      expanded,
    },
  })

  useEffect(() => {
    const searchQuery = normalizeText(filter)
    const newExpandedState = getSearchExpandedState(searchQuery, categoryRows)
    setExpanded(newExpandedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]) // We don't want the expanded state to be reset on every checkbox diff

  return (
    <div className="flex flex-col px-4">
      <div className="flex items-center justify-between gap-2 pb-2">
        <p>Manage roles and permissions in this workspace.</p>
        {canEdit && (
          <div className="flex items-center gap-2">
            <Button
              className="shrink-0"
              variant="outline"
              onClick={() => setIsCreateRoleModalOpen(true)}
            >
              <Icon icon={PlusIcon} className="mr-1" />
              Create role
            </Button>
            <Button
              className="shrink-0"
              variant="outline"
              disabled={isEditing}
              onClick={() => {
                setIsEditing(!isEditing)
              }}
            >
              <Icon icon={Pencil} className="mr-1" />
              Edit table
            </Button>
          </div>
        )}
      </div>

      <div className="mb-4 flex items-center justify-between gap-2">
        <SearchInput value={filter} setValue={setFilter} withIcon />
        {violations.length > 0 && !isClientAdminView && (
          <Tooltip>
            <TooltipTrigger>
              <div className="flex items-center gap-1 text-sm">
                <AlertIcon size="small" />
                {pluralize('violation', violations.length, true)}
              </div>
            </TooltipTrigger>
            <TooltipContent side="right" className="max-w-96">
              {violations.map((violation) => {
                const role = roles.find(
                  (role) => role.rolePk === violation.roleId
                )
                const label = `${violation.permBundleId} - ${role?.name}`
                return (
                  <div key={label} className="py-0.5 text-sm">
                    {label}
                  </div>
                )
              })}
            </TooltipContent>
          </Tooltip>
        )}
      </div>

      <DataTable
        className={cn(isEditing && 'rounded-b-none')}
        table={table}
        marginTop={75}
        isLoading={isLoading}
        stickyFirstColumn
        tableHeaderCellClassName="p-1 h-8"
        tableCellClassName="[&:has([role=checkbox])]:px-1 p-2 px-3"
        tableRowClassName={(row) =>
          cn({
            'bg-secondary hover:bg-secondary': row.original.type === 'category',
            'hover:bg-transparent': row.original.type === 'permBundle',
            'hover:bg-transparent border-t-0': row.original.type === 'perm',
            'border-b-0':
              (row.original.type === 'perm' && !row.original.isLast) ||
              (row.original.type === 'permBundle' &&
                row.getIsExpanded() &&
                row.original.children?.length),
            'cursor-default': row.original.type !== 'category', // Because onRowClick is only defined for categories
          })
        }
        onRowClick={(row) => {
          if (row.original.type === 'category') {
            row.toggleExpanded()
          }
        }}
      />

      {isEditing && (
        <div className="sticky inset-x-0 bottom-0 z-10 -mt-px flex items-center justify-between rounded-b-lg border bg-primary p-4">
          <div className="text-sm text-muted">
            {getNumDiffs(editDiffs)} unsaved changes
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="ghost"
              onClick={() => {
                setIsEditing(false)
                setEditDiffs(getInitialEditDiffs())
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveEdits}
              disabled={getNumDiffs(editDiffs) === 0}
            >
              Save
            </Button>
          </div>
        </div>
      )}

      <CreateRoleModal
        open={isCreateRoleModalOpen}
        onOpenChange={setIsCreateRoleModalOpen}
        onAdd={handleCreateRole}
      />
      {updatingRole && (
        <WorkspaceUpdateRoleDialog
          isOpen
          onClose={() => setUpdatingRole(undefined)}
          onSubmit={async ({ name, description }) => {
            await handleUpdateRole({
              rolePk: updatingRole.rolePk,
              name,
              description,
            })
          }}
          initialName={updatingRole.name}
          initialDescription={updatingRole.desc}
        />
      )}
      {confirmingDeleteRole && (
        <Dialog open onOpenChange={() => setConfirmingDeleteRole(undefined)}>
          <WorkspaceDeleteRoleDialog
            isOpen
            onClose={() => setConfirmingDeleteRole(undefined)}
            onDelete={handleDeleteRole}
            role={confirmingDeleteRole}
            availableRoles={roles}
            workspaceSlug={workspace.slug}
          />
        </Dialog>
      )}
    </div>
  )
}

export default WorkspaceRolesTableV2
