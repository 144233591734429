import React from 'react'

import { SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis } from 'openapi/models/SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis'

import { cn } from 'utils/utils'

import { Button } from 'components/ui/button'

import StatusBadge from './status-badge'

interface CountryCardProps {
  className?: string
  countryName: string
  analysis: SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis
  setSelectedCountry: (country: string) => void
}

const formatCurrency = (value: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)
}

const DataRow: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  return (
    <div className="grid grid-cols-10 gap-2">
      <p className="col-span-7 text-xs text-muted">{label}</p>
      <p className="col-span-3 text-xs">
        {value.charAt(0).toUpperCase() + value.slice(1)}
      </p>
    </div>
  )
}

export const computeStatus = (
  analysis: SocketMessageResponseAntitrustFilingsAllOfMetadataCountryCodeToAnalysis
) => {
  if (!analysis.thresholds?.length) {
    return 'No merger control regime'
  }

  const metThresholds = analysis.thresholds.filter(
    (threshold) => threshold.overallConclusion === 'likely to be met'
  ).length

  const unmetThresholds = analysis.thresholds.filter(
    (threshold) => threshold.overallConclusion === 'not likely to be met'
  ).length

  if (metThresholds > 0) {
    return `${metThresholds} Threshold${metThresholds > 1 ? 's' : ''} met`
  }

  if (unmetThresholds === analysis.thresholds.length) {
    return 'Thresholds not met'
  }

  return 'More information required'
}

const CountryCard: React.FC<CountryCardProps> = ({
  className,
  countryName,
  analysis,
  setSelectedCountry,
}) => {
  return (
    <Button
      variant="unstyled"
      className={cn(
        'flex h-full transform flex-col items-start justify-start rounded border border-transparent bg-secondary px-4 py-3 text-left transition hover:border-primary',
        className
      )}
      onClick={() => setSelectedCountry(countryName)}
    >
      <div className="flex w-full items-center justify-between">
        <p className="text-base">{analysis.countryName}</p>
        <div className="flex items-center gap-2">
          {/* <Badge variant="secondary">{computeStatus(analysis)}</Badge> */}
          <StatusBadge label={computeStatus(analysis)} />
        </div>
      </div>
      <div className="mt-4 w-full space-y-4">
        <div className="w-full">
          <p className="mb-1 text-xs font-medium">Local Turnover</p>
          <div className="space-y-1">
            <DataRow
              label="Acquirer"
              value={formatCurrency(analysis.acquirerRevenue ?? 0)}
            />
            <DataRow
              label="Target"
              value={formatCurrency(analysis.targetRevenue ?? 0)}
            />
            <DataRow
              label="Combined"
              value={formatCurrency(
                (analysis.acquirerRevenue ?? 0) + (analysis.targetRevenue ?? 0)
              )}
            />
          </div>
        </div>
        <div className="w-full">
          <p className="mb-1 text-xs font-medium">Thresholds</p>
          <div className="space-y-1">
            {analysis.thresholds.map((threshold) => (
              <DataRow
                key={threshold.name}
                label={threshold.name}
                value={threshold.overallConclusion}
              />
            ))}
          </div>
        </div>
      </div>
    </Button>
  )
}

export default CountryCard
