import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { WaypointsIcon } from 'lucide-react'

import { CompletedWorkflowFooter } from './components/completed-workflow-footer'
import { AssistantWorkflowFilePopover } from './components/workflow-file-popover'
import AssistantShareMenu from 'components/assistant/components/assistant-share-menu'
import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import AOLogo from 'components/assistant/workflows/blocks/antitrust-filing-analysis/summary/ao-logo'
import { AssistantWorkflowThreadBlock } from 'components/assistant/workflows/blocks/assistant-workflow-block-layout'
import { useWorkflowAnalytics } from 'components/assistant/workflows/hooks/use-workflow-analytics'
import { AppHeader } from 'components/common/app-header'
import { AppMain } from 'components/common/app-main'
import { useAuthUser } from 'components/common/auth-context'
import RouterBreadcrumbs from 'components/common/router-breadcrumbs'
import { Badge } from 'components/ui/badge'
import Icon from 'components/ui/icon/icon'
import {
  ImperativeResizablePanelGroupHandle,
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { ScrollArea } from 'components/ui/scroll-area'

import { useAssistantWorkflowStore } from './stores/assistant-workflow-store'
import { WorkflowExportContainer } from './workflow-export-container'

type Params = {
  title: string
  input: React.ReactNode
  thread: React.ReactNode[]
}

export const AssistantWorkflowLayout: React.FC<Params> = ({
  title,
  input,
  thread,
}) => {
  const userInfo = useAuthUser()
  const trackEvent = useWorkflowAnalytics()
  const showAOLogo = title === 'Antitrust Filings'
  const logo = showAOLogo ? <AOLogo className="h-2" /> : null
  const resizablePanelGroupRef =
    useRef<ImperativeResizablePanelGroupHandle | null>(null)
  const defaultResizablePanelSizes = [50, 50]
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)
  const currentEvent = useAssistantWorkflowStore((state) => state.currentEvent)
  const eventId = currentEvent?.eventId
  const isWorkflowCompleted = currentEvent?.isCompleted
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true)
  const scrollViewportRef = useRef<HTMLDivElement | null>(null)
  const distanceFromBottomRef = useRef<number>(0)

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    // Listen for scroll events. If the user is at/near the bottom, we enable autoScroll.
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget
    const isAtBottom = scrollHeight - scrollTop <= clientHeight + 5
    setShouldAutoScroll(isAtBottom)

    // Also record how far from bottom the user currently is
    distanceFromBottomRef.current = scrollHeight - scrollTop - clientHeight
  }

  useEffect(() => {
    const viewportEl = scrollViewportRef.current
    if (!viewportEl || !shouldAutoScroll) return

    // measure the new distance from bottom after messages are added
    const { scrollHeight, scrollTop, clientHeight } = viewportEl
    const newDistanceFromBottom = scrollHeight - scrollTop - clientHeight
    const amountWeWouldScroll =
      newDistanceFromBottom - distanceFromBottomRef.current

    // skip if the new chunk is larger than half screen height:
    const threshold = window.innerHeight / 2

    if (amountWeWouldScroll < threshold) {
      // if we *are* auto-scrolling and that new content is not huge:
      viewportEl.scrollTop = viewportEl.scrollHeight
    }
  }, [thread, shouldAutoScroll])

  useEffect(() => {
    if (currentEvent && isWorkflowCompleted) {
      trackEvent('Workflow Completed', {
        workflow_name: title,
      })
    }
  }, [currentEvent, isWorkflowCompleted, trackEvent, title])

  const resetLayout = () => {
    const panelGroup = resizablePanelGroupRef.current
    if (panelGroup) {
      panelGroup.setLayout(defaultResizablePanelSizes)
    }
  }

  const actions = (
    <div className="inline-flex items-center space-x-2">
      {userInfo.IsEventCreateSharesUser && (
        <AssistantShareMenu eventId={eventId ? String(eventId) : null} />
      )}
      <WorkflowExportContainer />
    </div>
  )

  return (
    <AppMain>
      <AppHeader
        breadcrumbs={<RouterBreadcrumbs overridePath={`/assistant/${title}`} />}
        actions={actions}
        titleComponent={
          <div className="flex items-center gap-2">
            <h1 className="line-clamp-2 whitespace-pre-wrap text-xl font-medium">
              {title}
            </h1>
            <Badge
              variant="secondary"
              className="flex items-center gap-[6px] px-[6px] py-1 transition hover:bg-button-secondary"
            >
              <Icon icon={WaypointsIcon} size="small" className="text-muted" />
              <span className="text-2xs font-semibold uppercase text-muted">
                Workflow
              </span>
            </Badge>
            {logo}
          </div>
        }
      />
      <ResizablePanelGroup direction="horizontal" ref={resizablePanelGroupRef}>
        <ResizablePanel
          id="thread"
          defaultSize={fileId ? defaultResizablePanelSizes[0] : 100}
          minSize={20}
          order={1}
        >
          <div className="flex h-full flex-col">
            <ScrollArea
              ref={scrollViewportRef}
              onScroll={handleScroll}
              className="grow"
            >
              <div className="space-y-4 py-6">{thread}</div>
            </ScrollArea>
            {isWorkflowCompleted ? (
              <CompletedWorkflowFooter />
            ) : (
              <AssistantWorkflowThreadBlock>
                {input}
              </AssistantWorkflowThreadBlock>
            )}
          </div>
        </ResizablePanel>
        {!!fileId && (
          <>
            <ResizableHandle withHandle onDoubleClick={resetLayout} />
            <ResizablePanel
              id="viewer"
              className="z-0"
              defaultSize={defaultResizablePanelSizes[1]}
              minSize={20}
              order={2}
            >
              <AssistantWorkflowFilePopover />
            </ResizablePanel>
          </>
        )}
      </ResizablePanelGroup>
    </AppMain>
  )
}
