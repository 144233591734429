import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { convertMillimetersToTwip } from 'docx'
import { HeadingLevel } from 'docx'
import { useShallow } from 'zustand/react/shallow'

import { EventStatus } from 'openapi/models/EventStatus'
import { Source } from 'types/task'

import { getDocxContentType, WordSection, WordSectionType } from 'utils/docx'
import { exportAsFormattedWordDocx } from 'utils/export'
import { displaySuccessMessage, displayErrorMessage } from 'utils/toast'

import {
  AssistantWorkflowSourceExtractor,
  AssistantWorkflowStepNameToDefinition,
  BlockTypeMap,
} from 'components/assistant/workflows'
import { useWorkflowAnalytics } from 'components/assistant/workflows/hooks/use-workflow-analytics'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { Button } from 'components/ui/button'

interface ExportComponentProps {
  stepIdx: number
  blockParams: BlockTypeMap[keyof BlockTypeMap]['blockParams']
  outputData: BlockTypeMap[keyof BlockTypeMap]['outputData']
}

export const WorkflowExportContainer: React.FC = () => {
  const [currentEvent, workflowDefinition] = useAssistantWorkflowStore(
    useShallow((state) => [state.currentEvent, state.workflowDefinition])
  )
  const trackEvent = useWorkflowAnalytics()

  const elRefs = useRef<HTMLDivElement[]>([])

  useEffect(() => {
    elRefs.current = []
  }, [currentEvent?.eventId])

  const isExportDisabled = useMemo(() => {
    if (!currentEvent?.steps) {
      return true
    }

    return !['COMPLETED', 'ERRORED'].includes(
      currentEvent.steps[currentEvent.steps.length - 1].paramStatus
    )
  }, [currentEvent?.steps])

  const completedSteps = useMemo(
    () =>
      currentEvent?.steps.filter(
        (step) => step.paramStatus === EventStatus.COMPLETED
      ) || [],
    [currentEvent]
  )

  const handleExport = useCallback(async () => {
    if (elRefs.current.length === 0) {
      return
    }

    const sections: WordSection[] = []

    elRefs.current.forEach((ref) => {
      let cleanedContent = ref.innerHTML
      let previousContent
      do {
        previousContent = cleanedContent
        cleanedContent = cleanedContent.replace(
          /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi,
          ''
        )
      } while (cleanedContent !== previousContent)
      sections.push({
        content: cleanedContent,
        type: getDocxContentType(ref.innerHTML),
      })
    })

    const sources = completedSteps
      .map((step) => {
        const blockParams = step.blockParams as any
        const sourceExtractor = AssistantWorkflowStepNameToDefinition[
          workflowDefinition?.steps.find((s) => s.id === step.blockId)
            ?.blockId as keyof BlockTypeMap
        ].sourceExtractor as
          | AssistantWorkflowSourceExtractor<keyof BlockTypeMap>
          | undefined
        if (!sourceExtractor) return []
        const sources = sourceExtractor(blockParams)
        return sources
      })
      .flat()

    sections.push({
      content: sources as Source[],
      options: {
        heading: HeadingLevel.HEADING_3,
        spacing: {
          before: convertMillimetersToTwip(1),
          after: convertMillimetersToTwip(1),
        },
      },
      type: WordSectionType.SOURCES,
    })

    exportAsFormattedWordDocx({
      title: workflowDefinition?.name ?? 'Workflow',
      sections,
    })
      .then(() => {
        trackEvent('Workflow Exported', {
          workflow_name: workflowDefinition?.name,
          format: 'word',
        })
        displaySuccessMessage('Exported successfully')
      })
      .catch(() => displayErrorMessage('Sorry, something went wrong'))
    // XXX: Doing completedSteps.length as a dependency rather than completedSteps
    // This is because completedSteps is a new array every time, so it would trigger
    // a re-render every time, which is not necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    completedSteps.length,
    workflowDefinition?.name,
    workflowDefinition?.steps,
  ])

  return useMemo(
    () => (
      <>
        <Button onClick={handleExport} disabled={isExportDisabled}>
          Export
        </Button>
        <div className="hidden">
          {completedSteps.map((step, index) => {
            const ExportComponent = AssistantWorkflowStepNameToDefinition[
              workflowDefinition?.steps.find((s) => s.id === step.blockId)
                ?.blockId as keyof BlockTypeMap
            ].ExportComponent as React.FC<ExportComponentProps> | undefined

            if (!ExportComponent) return null
            return (
              <div
                key={step.stepIdx}
                ref={(el: HTMLDivElement) => {
                  elRefs.current[index] = el
                }}
              >
                <ExportComponent
                  stepIdx={index}
                  blockParams={step.blockParams as any}
                  outputData={step.outputData as any}
                />
              </div>
            )
          })}
        </div>
      </>
    ),
    // XXX: Doing completedSteps.length as a dependency rather than completedSteps
    // This is because completedSteps is a new array every time, so it would trigger
    // a re-render every time, which is not necessary.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [completedSteps.length, handleExport]
  )
}
