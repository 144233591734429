import React, { useCallback } from 'react'
import { useEffect } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useSearchParams } from 'react-router-dom'

import pluralize from 'pluralize'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { useShallow } from 'zustand/react/shallow'

import { uploadFile } from 'api'
import { DocumentKnowledgeSource } from 'openapi/models/DocumentKnowledgeSource'
import { KnowledgeSource } from 'openapi/models/KnowledgeSource'
import { KnowledgeSourceType } from 'openapi/models/KnowledgeSourceType'
import { UploadedFile } from 'openapi/models/UploadedFile'
import { VaultFile } from 'openapi/models/VaultFile'
import { WorkflowFileUploadInputBlockBlockParams } from 'openapi/models/WorkflowFileUploadInputBlockBlockParams'
import { WorkflowInputComponentBlocks } from 'openapi/models/WorkflowInputComponentBlocks'
import { useFileCache } from 'stores/file-cache'
import {
  FileType,
  FileTypeReadableName,
  isFileViewableInAssistant,
  removeSubsetDuplicates,
} from 'types/file'

import { onDrop } from 'utils/dropzone'
import { bytesToMb, bytesToReadable } from 'utils/file-utils'
import { displayErrorMessage } from 'utils/toast'
import { cn } from 'utils/utils'

import { ExtraSpaceFileItem } from 'components/assistant/components/assistant-files'
import VaultKnowledgeSourcePicker from 'components/assistant/features/composer/assistant-vault-ks-picker'
import { useAssistantKsOptions } from 'components/assistant/hooks/use-assistant-ks-options'
import { FILE_ID_PARAM } from 'components/assistant/utils/assistant-helpers'
import {
  FileSource,
  KnowledgeSourceItem,
  VaultKnowledgeSource,
} from 'components/assistant/utils/assistant-knowledge-sources'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
} from 'components/assistant/workflows'
import { AssistantWorkflowFilesComponent } from 'components/assistant/workflows/components/assistant-workflow-files-component'
import { useSetViewingFile } from 'components/assistant/workflows/components/workflow-file-popover'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useWorkflowAnalytics } from 'components/assistant/workflows/hooks/use-workflow-analytics'
import { useAssistantWorkflowStore } from 'components/assistant/workflows/stores/assistant-workflow-store'
import { Dropzone } from 'components/common/dropzone/dropzone'
import DropzoneDescription from 'components/common/dropzone/dropzone-description'
import KsInputDropdown from 'components/common/ks-input-dropdown'
import { Button } from 'components/ui/button'
import { DropdownMenuSeparator } from 'components/ui/dropdown-menu'
import { ScrollArea } from 'components/ui/scroll-area'
import { useVaultStore } from 'components/vault/utils/vault-store'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
  AssistantWorkflowYouComponent,
} from './assistant-workflow-block-layout'

const DEFAULT_BLOCK_PARAMS: WorkflowFileUploadInputBlockBlockParams = {
  headerText: 'Upload a file',
  maxFileSizeBytes: 1024 * 1024 * 10, // 10MB
  maxTotalFileSizeBytes: 1024 * 1024 * 10, // 10MB
  minFileCount: 1,
  maxFileCount: 1,
  acceptedFileMimeTypes: ['application/pdf'],
}

interface BlockStoreState {
  uploadedFiles: UploadedFile[] | null
  knowledgeSource: KnowledgeSourceItem | null
  showVaultDialog: boolean
  isLoading: boolean
}

interface BlockStoreActions {
  setUploadedFiles: (file: UploadedFile[] | null) => void
  setKnowledgeSource: (knowledgeSource: KnowledgeSourceItem | null) => void
  setShowVaultDialog: (showVaultDialog: boolean) => void
  setIsLoading: (loading: boolean) => void
  removeFileIds: (fileIds: string[]) => void
}

const initialState: BlockStoreState = {
  uploadedFiles: null,
  knowledgeSource: null,
  showVaultDialog: false,
  isLoading: false,
}

// TODO: Need to somehow reset the store at certain points
const createBlockStore = () =>
  create(
    devtools(
      immer<BlockStoreState & BlockStoreActions>((set) => ({
        ...initialState,

        reset: () => set(() => initialState),
        setUploadedFiles: (files) =>
          set((state) => {
            state.uploadedFiles = files
          }),
        setKnowledgeSource: (knowledgeSource) =>
          set((state) => {
            state.knowledgeSource = knowledgeSource
          }),
        setShowVaultDialog: (showVaultDialog) =>
          set((state) => {
            state.showVaultDialog = showVaultDialog
          }),
        setIsLoading: (loading) =>
          set((state) => {
            state.isLoading = loading
          }),
        removeFileIds: (fileIds: string[]) => {
          set((state) => {
            if (
              !state.knowledgeSource ||
              !('fileIds' in state.knowledgeSource) ||
              !state.knowledgeSource.fileIds
            )
              return
            const newIds = state.knowledgeSource.fileIds.filter(
              (id) => !fileIds.includes(id)
            )
            if (newIds.length) {
              state.knowledgeSource.fileIds = newIds
            } else {
              state.knowledgeSource = null
            }
          })
        },
      }))
    )
  )

// TODO: Can we make this zudstand stuff more generic for other blocks?
export const useBlockStore = (
  stepIdx: number
): ReturnType<typeof createBlockStore> => {
  const [blockStores, addBlockStore] = useAssistantWorkflowStore(
    useShallow((state) => [state.blockStores, state.addBlockStore])
  )

  if (!blockStores[stepIdx]) {
    const blockStore = createBlockStore()
    addBlockStore(stepIdx, blockStore)
    return blockStore
  }

  return blockStores[stepIdx]
}

export type FileToShow = UploadedFile & {
  fileType?: FileType
  description: string
}

export const AssistantWorkflowFileUploadThread: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({ blockParams: _blockParams, outputData, stepIdx, completionStatus }) => {
  const blockParams = { ...DEFAULT_BLOCK_PARAMS, ..._blockParams }
  const { headerText } = blockParams
  const [uploadedFiles, setUploadedFiles, knowledgeSource] = useBlockStore(
    stepIdx
  )(
    useShallow((state) => [
      state.uploadedFiles,
      state.setUploadedFiles,
      state.knowledgeSource,
    ])
  )

  const [fileIdToVaultFile] = useVaultStore(
    useShallow((s) => [s.fileIdToVaultFile])
  )
  const getFile = useFileCache((state) => state.getFile)
  const setViewingFile = useSetViewingFile()
  const [searchParams] = useSearchParams()
  const fileId = searchParams.get(FILE_ID_PARAM)

  const getFileIdsFromOutputData = useCallback(() => {
    if (!outputData?.knowledgeSources) return []
    const knowledgeSources =
      outputData.knowledgeSources as DocumentKnowledgeSource[]

    const fileIds = knowledgeSources.reduce<string[]>((acc, ks) => {
      if (
        [FileSource.FILES, FileSource.VAULT].includes(ks.type as FileSource)
      ) {
        acc.push(...ks.fileIds)
      }
      return acc
    }, [])
    return fileIds
  }, [outputData])

  useEffect(() => {
    if (
      knowledgeSource === null &&
      uploadedFiles === null &&
      outputData !== null
    ) {
      const fileIds = getFileIdsFromOutputData()

      Promise.all(fileIds.map(getFile))
        .then((files) =>
          setUploadedFiles(
            files.filter((f): f is UploadedFile => f !== undefined)
          )
        )
        .catch((e) => {
          console.error(e)
        })
    }
  }, [
    uploadedFiles,
    outputData,
    setUploadedFiles,
    getFile,
    getFileIdsFromOutputData,
    knowledgeSource,
  ])

  const onFileClick = (file: UploadedFile) => {
    setViewingFile(file)
  }

  const allFiles = React.useMemo(() => {
    const files = uploadedFiles ?? []
    const vaultFileIds =
      knowledgeSource && 'fileIds' in knowledgeSource && knowledgeSource.fileIds
        ? knowledgeSource.fileIds
        : []
    const activeFiles = vaultFileIds
      .map((fileId) => fileIdToVaultFile[fileId])
      .filter(Boolean) as VaultFile[]

    return [...files, ...activeFiles]
  }, [knowledgeSource, uploadedFiles, fileIdToVaultFile])

  const filesToShow = allFiles.reduce((acc: FileToShow[], file) => {
    const ksFileIds = getFileIdsFromOutputData()
    if (ksFileIds.includes(file.id)) {
      const fileType = file.contentType as FileType | null | undefined
      let description = ''
      if (fileType) {
        description = `${FileTypeReadableName[fileType]} document • ${
          file.size ? bytesToReadable(file.size) : ''
        }`
      } else {
        description = `Document • ${
          file.size ? bytesToReadable(file.size) : ''
        }`
      }
      acc.push({ ...file, fileType: fileType || undefined, description })
    }
    return acc
  }, [])

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        <AssistantWorkflowThreadText
          completionStatus={completionStatus}
          text={headerText}
        />
      </AssistantWorkflowHarveyComponent>
      {!!outputData && (
        <AssistantWorkflowYouComponent>
          {filesToShow.length > 0 ? (
            <AssistantWorkflowFilesComponent
              files={filesToShow}
              onFileClick={onFileClick}
              fileId={fileId}
            />
          ) : (
            <AssistantWorkflowThreadText
              completionStatus={completionStatus}
              text="Skipped"
            />
          )}
        </AssistantWorkflowYouComponent>
      )}
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowFileUploadInput: AssistantWorkflowComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({
  blockParams: _blockParams,
  onCompleted,
  stepIdx,
  outputData,
  workflowName,
}) => {
  const trackEvent = useWorkflowAnalytics()
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [isFileListOpen, setisFileListOpen] = React.useState(true)
  const blockParams = { ...DEFAULT_BLOCK_PARAMS, ..._blockParams }
  const [getEventId, pendingMessage] = useAssistantWorkflowStore(
    useShallow((state) => [state.getEventId, state.pendingMessage])
  )
  const [
    isLoading,
    uploadedFiles,
    setUploadedFiles,
    setIsLoading,
    knowledgeSource,
    setKnowledgeSource,
    showVaultDialog,
    setShowVaultDialog,
    removeFileIds,
  ] = useBlockStore(stepIdx)(
    useShallow((state) => [
      state.isLoading,
      state.uploadedFiles,
      state.setUploadedFiles,
      state.setIsLoading,
      state.knowledgeSource,
      state.setKnowledgeSource,
      state.showVaultDialog,
      state.setShowVaultDialog,
      state.removeFileIds,
    ])
  )
  const setViewingFile = useSetViewingFile()

  const addFile = useFileCache((state) => state.addFile)

  const onFileClick = (file: UploadedFile) => {
    setViewingFile(file)
  }

  const onSubmit = () => {
    const knowledgeSources: KnowledgeSource[] = []

    if (uploadedFiles?.length) {
      const docKS: DocumentKnowledgeSource = {
        type: KnowledgeSourceType.FILES,
        fileIds: uploadedFiles.map((file) => file.id),
      }
      knowledgeSources.push(docKS as any)
    }
    if (
      knowledgeSource &&
      'fileIds' in knowledgeSource &&
      knowledgeSource.fileIds
    ) {
      const vaultKS: VaultKnowledgeSource = {
        type: FileSource.VAULT,
        fileIds: knowledgeSource.fileIds,
        folderId: knowledgeSource.folderId,
      }
      knowledgeSources.push(vaultKS as any)
    }

    onCompleted({
      knowledgeSources,
    })
  }

  const handleRemoveFile = (file: UploadedFile | any) => {
    setUploadedFiles(
      uploadedFiles?.filter((f) => f.id !== file.id) ?? uploadedFiles
    )
    removeFileIds([file.id])
  }

  const handleRemoveAll = () => {
    setUploadedFiles(null)
  }

  const handleAcceptedFiles = async (files: File[]) => {
    if (!files.length) {
      displayErrorMessage('No files were accepted')
      setIsLoading(false)
      return
    }

    const eventId = await getEventId()

    // TODO: Do we have a batch upload API?
    // TODO: Error handling
    Promise.all(
      files.map((file) =>
        uploadFile(file, false, {
          eventId: eventId ? String(eventId) : undefined,
        }).then((uploaded) => {
          addFile(uploaded) // Add to file cache for quick retrieval
          trackEvent('Workflow File Uploaded', {
            step_idx: stepIdx,
            workflow_name: workflowName,
            file_size: uploaded.size,
            file_type: uploaded.contentType,
          })
          return uploaded
        })
      )
    )
      .then((newUploadedFiles) => {
        setUploadedFiles([...newUploadedFiles, ...(uploadedFiles ?? [])])
      })
      .catch((e) => {
        displayErrorMessage('Failed to upload file')
        console.error(e)
      })
      // TODO: It's weird to set isLoading to false here and true in onFileDrop
      .finally(() => setIsLoading(false))
  }

  const onFileDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    // TODO: Handle file rejections
    setIsLoading(true)
    return onDrop({
      acceptedFiles: acceptedFiles,
      fileRejections,
      currentFileCount: uploadedFiles?.length ?? 0,
      maxFiles: blockParams.maxFileCount!,
      acceptedFileTypes: blockParams.acceptedFileMimeTypes! as FileType[],
      maxFileSize: blockParams.maxFileSizeBytes!,
      maxTotalFileSizeProps: {
        maxTotalFileSize: blockParams.maxTotalFileSizeBytes!,
        currentTotalFileSize:
          uploadedFiles?.reduce((total, file) => total + (file.size ?? 0), 0) ??
          0,
      },
      handleAcceptedFiles,
      handleRejectedFiles: () => setIsLoading(false),
    })
  }

  const componentDisabled = !!isLoading || !!outputData || pendingMessage

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onFileDrop,
    maxFiles: blockParams.maxFileCount,
    maxSize: blockParams.maxTotalFileSizeBytes,
    disabled: componentDisabled,
  })

  const onUploadFromComputer = () => {
    open()
  }

  const { chooseFilesDropdownOptions } = useAssistantKsOptions({
    onUploadFromComputer,
    setShowDialog: setShowVaultDialog,
    onDrop: onFileDrop,
    maxFileCount: blockParams.maxFileCount!,
    maxFileSize: Number(bytesToMb(blockParams.maxFileSizeBytes!)),
  })

  const [fileIdToVaultFile] = useVaultStore(
    useShallow((s) => [s.fileIdToVaultFile])
  )

  const allFiles = React.useMemo(() => {
    const files = uploadedFiles ?? []
    const vaultFileIds =
      knowledgeSource && 'fileIds' in knowledgeSource && knowledgeSource.fileIds
        ? knowledgeSource.fileIds
        : []
    const activeFiles = vaultFileIds
      .map((fileId) => fileIdToVaultFile[fileId])
      .filter(Boolean) as VaultFile[]

    return [...files, ...activeFiles]
  }, [knowledgeSource, uploadedFiles, fileIdToVaultFile])

  const footer = allFiles.length
    ? `${allFiles.length} ${pluralize('file', allFiles.length)} attached`
    : blockParams.maxFileCount === 1
    ? 'Upload a file'
    : `Upload up to ${blockParams.maxFileCount} ${pluralize(
        'file',
        blockParams.maxFileCount
      )}`

  const isSkippable = blockParams.minFileCount === 0
  const requiresFile = !allFiles.length && !isSkippable
  const allowsMultipleFiles = blockParams.maxFileCount! > 1

  return (
    <WorkflowInput className={cn(!isFileListOpen && '[&>*]:space-y-0')}>
      {allFiles.length ? (
        <>
          <div
            className={cn(
              'rounded-md bg-primary ',
              isFileListOpen ? 'visible' : 'hidden'
            )}
          >
            {allowsMultipleFiles && (
              <div className="flex justify-between px-4 pb-1 pt-3.5">
                <h4>Files</h4>
                <div className="space-x-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={open}
                    disabled={componentDisabled}
                  >
                    Attach more
                  </Button>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={handleRemoveAll}
                    disabled={componentDisabled}
                  >
                    Remove all
                  </Button>
                </div>
              </div>
            )}

            <ul className="h-full divide-y px-1.5 py-2">
              <ScrollArea
                maxHeight={cn(
                  'max-h-[244.75px]',
                  allFiles.length > 5 && 'pr-2'
                )}
              >
                {allFiles.map((file, i) => (
                  <ExtraSpaceFileItem
                    key={i}
                    file={file}
                    hasBorder={i !== allFiles.length - 1}
                    handleRemoveFile={handleRemoveFile}
                    isRemoveDisabled={componentDisabled}
                    handleFileClick={
                      isFileViewableInAssistant(file.contentType ?? undefined)
                        ? onFileClick
                        : undefined
                    }
                  />
                ))}
              </ScrollArea>
            </ul>
          </div>
          <WorkflowInputFooter
            className={cn(!isFileListOpen && 'mt-0')}
            footer={footer}
          >
            {allFiles.length > 1 && (
              <>
                <span className="-ml-6 -mr-2.5 text-muted">・</span>
                {isFileListOpen ? (
                  <Button
                    variant="text"
                    onClick={() => setisFileListOpen(false)}
                    className="mr-auto"
                  >
                    Hide
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    onClick={() => setisFileListOpen(true)}
                    className="mr-auto"
                  >
                    Show
                  </Button>
                )}
              </>
            )}
            <Button
              onClick={onSubmit}
              isLoading={isLoading || !!outputData}
              disabled={requiresFile}
            >
              Send
            </Button>
          </WorkflowInputFooter>
        </>
      ) : (
        <>
          <div>
            <Dropzone
              className="h-60 rounded-md bg-primary"
              isLoading={isLoading}
              isDisabled={componentDisabled}
              description={
                <DropzoneDescription
                  fileTypes={blockParams.acceptedFileMimeTypes as FileType[]}
                  maxSize={bytesToReadable(blockParams.maxFileSizeBytes!)}
                >
                  <KsInputDropdown
                    triggerComponent={
                      <Button
                        size="sm"
                        className="mt-2"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        Choose file
                      </Button>
                    }
                    dropdownItems={chooseFilesDropdownOptions}
                    dropdownOpen={dropdownOpen}
                    setDropdownOpen={setDropdownOpen}
                    positionSide="top"
                    positionAlign="center"
                  >
                    <DropdownMenuSeparator className="-mx-2 my-2" />
                    <p className="line-clamp-2 max-w-64 p-2 text-xs text-muted">
                      Supported file types:{' '}
                      {removeSubsetDuplicates(
                        (blockParams.acceptedFileMimeTypes as FileType[]).map(
                          (fileType) => FileTypeReadableName[fileType]
                        )
                      )
                        .sort((a, b) => a.localeCompare(b))
                        .join(', ')}
                    </p>
                  </KsInputDropdown>
                </DropzoneDescription>
              }
              dropzone={{ getRootProps, getInputProps }}
            />
            <VaultKnowledgeSourcePicker
              onClose={() => setKnowledgeSource(null)}
              showDialog={showVaultDialog}
              setShowDialog={setShowVaultDialog}
              knowledgeSource={knowledgeSource}
              setKnowledgeSource={setKnowledgeSource}
              numberOfFilesLimit={blockParams.maxFileCount}
            />
          </div>
          <WorkflowInputFooter footer={footer}>
            <Button
              onClick={onSubmit}
              isLoading={isLoading || !!outputData || pendingMessage}
              disabled={requiresFile}
            >
              {!requiresFile ? 'Skip' : 'Send'}
            </Button>
          </WorkflowInputFooter>
        </>
      )}
    </WorkflowInput>
  )
}

export const AssistantWorkflowFileUploadExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowInputComponentBlocks.FILE_UPLOAD
> = ({ blockParams, stepIdx }) => {
  const { headerText } = blockParams

  const uploadedFiles = useBlockStore(stepIdx)(
    useShallow((state) => state.uploadedFiles)
  )

  return (
    <>
      <div>{headerText}</div>
      {(uploadedFiles ?? []).map((file) => (
        <div key={file.id}>
          <p>{file.name}</p>
        </div>
      ))}
    </>
  )
}
