import { useParams } from 'react-router-dom'

import { Dict } from 'mixpanel-browser'

import { useAnalytics } from 'components/common/analytics/analytics-context'

export const useWorkflowAnalytics = () => {
  const { trackEvent } = useAnalytics()
  const { id: workflowId, eventId: eventIdParam } = useParams()

  const sharedProperties: Dict = {}

  if (eventIdParam) sharedProperties['event_id'] = String(eventIdParam)
  if (workflowId) sharedProperties['workflow_id'] = String(workflowId)

  const trackAssistantEvent = (eventName: string, properties?: Dict) => {
    trackEvent(eventName, {
      ...sharedProperties,
      ...properties,
    })
  }

  return trackAssistantEvent
}
