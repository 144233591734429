import React, { useMemo } from 'react'

import { Download, FolderInput, RotateCw, Trash, X } from 'lucide-react'
import { useShallow } from 'zustand/react/shallow'

import { VaultFile } from 'openapi/models/VaultFile'

import { cn } from 'utils/utils'

import { useAnalytics } from 'components/common/analytics/analytics-context'
import Toolbelt, {
  ToolbeltButton,
  ToolbeltDivider,
} from 'components/ui/toolbelt'
import VaultDocumentClassification from 'components/vault/components/vault-document-classification'
import useRetryHandler from 'components/vault/hooks/use-retry-handler'
import { DOT_SEPARATOR, VaultItemType } from 'components/vault/utils/vault'
import { downloadFiles } from 'components/vault/utils/vault-exporter'
import { useVaultFileExplorerStore } from 'components/vault/utils/vault-file-explorer-store'
import { getSortedFilesBasedOnReviewQueryOrder } from 'components/vault/utils/vault-helpers'
import { useVaultStore } from 'components/vault/utils/vault-store'
import { pluralizeFiles } from 'components/vault/utils/vault-text-utils'

const VaultFileExplorerToolbelt = ({ className }: { className?: string }) => {
  const { trackEvent } = useAnalytics()

  const folderIdToVaultFolder = useVaultStore(
    useShallow((s) => s.folderIdToVaultFolder)
  )
  const fileIdToVaultFile = useVaultStore(
    useShallow((s) => s.fileIdToVaultFile)
  )
  const currentProjectMetadata = useVaultStore(
    useShallow((s) => s.currentProjectMetadata)
  )
  const upsertVaultFiles = useVaultStore((s) => s.upsertVaultFiles)
  const setDeleteRecords = useVaultStore((s) => s.setDeleteRecords)
  const setIsDeleteDialogOpen = useVaultStore((s) => s.setIsDeleteDialogOpen)
  const setMoveRecords = useVaultStore((s) => s.setMoveRecords)
  const setIsMoveDialogOpen = useVaultStore((s) => s.setIsMoveDialogOpen)
  const setRequiresProjectDataRefetch = useVaultStore(
    (state) => state.setRequiresProjectDataRefetch
  )

  const selectedRows = useVaultFileExplorerStore(
    useShallow((s) => s.selectedRows)
  )
  const setSelectedRows = useVaultFileExplorerStore((s) => s.setSelectedRows)

  // Normalize the selected rows to remove descendants of selected folders if all descendants are selected
  const normalizedSelectedRows = useMemo(() => {
    return selectedRows.filter((row) => {
      if (row.type !== VaultItemType.file && !row.isAllDescendantsSelected) {
        // If the row is a folder and not all descendants are selected, we don't want to include it in the normalized selected rows
        return false
      }
      if (row.type === VaultItemType.file && !row.data.path) {
        // Check that the path for the file exists, otherwise it is being uploaded (we cannot move or delete an uploading file)
        return false
      }
      const path = row.index.split('.')
      return !path.some((_, idx) => {
        if (idx === 0) {
          return false
        }
        const ancestorPath = path.slice(0, idx).join('.')
        return selectedRows.some(
          (r) => r.index === ancestorPath && r.isAllDescendantsSelected
        )
      })
    })
  }, [selectedRows])
  const selectedFiles = useMemo(() => {
    const files = selectedRows
      .filter((row) => row.type === VaultItemType.file)
      .map((item) => item.data as VaultFile)
      .filter(Boolean)
    return getSortedFilesBasedOnReviewQueryOrder(files, folderIdToVaultFolder)
  }, [selectedRows, folderIdToVaultFolder])

  const selectedReadyToQueryFiles = selectedFiles.filter(
    (file) => file.readyToQuery
  )

  const isToolbarVisible = selectedRows.length > 0
  const displayText = `${pluralizeFiles(selectedFiles.length)} selected`
  // Only show the ready to query files if not all selected files are ready to query
  const displayTextForReadyToQueryFiles =
    selectedReadyToQueryFiles.length < selectedFiles.length
      ? `${pluralizeFiles(selectedReadyToQueryFiles.length)} ready to query`
      : ''

  const onDeselectAllRows = () => {
    setSelectedRows([])
  }

  const selectedFailedFiles = selectedFiles.filter(
    // Only retry failed files that have a path (uploaded successfully)
    (file) => !file.readyToQuery && file.failureReason && file.path
  )
  const { onRetryHandler } = useRetryHandler()
  const onRetrySelectedRows = async () => {
    trackEvent('Vault Row Selection Retry Button Clicked', {
      num_files: selectedFailedFiles.length,
    })
    await onRetryHandler(selectedFailedFiles.map((file) => file.id))
    setSelectedRows([])
    setRequiresProjectDataRefetch(true)
  }
  const shouldShowRetryButton = selectedFailedFiles.length > 0

  const shouldShowMoveButton = normalizedSelectedRows.length > 0
  const onMoveSelectedRows = () => {
    trackEvent('Vault Row Selection Move Button Clicked', {
      num_files: normalizedSelectedRows.filter(
        (row) => row.type === VaultItemType.file
      ).length,
      num_folders: normalizedSelectedRows.filter(
        (row) =>
          row.type === VaultItemType.folder ||
          row.type === VaultItemType.project
      ).length,
    })
    setMoveRecords(normalizedSelectedRows)
    setIsMoveDialogOpen(true)
  }

  const shouldShowDownloadButton = selectedFiles.length > 0
  const onDownloadSelectedRows = async () => {
    trackEvent('Vault Row Selection Download Button Clicked', {
      num_files: selectedFiles.length,
    })
    await downloadFiles({
      fileIdsToDownload: selectedFiles.map((file) => file.id),
      fileIdToVaultFile: fileIdToVaultFile,
      downloadFileName: currentProjectMetadata.name,
      upsertVaultFiles: upsertVaultFiles,
      projectId: currentProjectMetadata.id,
    })
  }

  const shouldShowDeleteButton = normalizedSelectedRows.length > 0
  const onDeleteSelectedRows = () => {
    trackEvent('Vault Row Selection Delete Button Clicked', {
      num_files: normalizedSelectedRows.filter(
        (row) => row.type === VaultItemType.file
      ).length,
      num_folders: normalizedSelectedRows.filter(
        (row) =>
          row.type === VaultItemType.folder ||
          row.type === VaultItemType.project
      ).length,
    })
    setDeleteRecords(normalizedSelectedRows)
    setIsDeleteDialogOpen(true)
  }

  const isFilesProcessing =
    currentProjectMetadata.completedFiles !== currentProjectMetadata.totalFiles
  const shouldShowDocumentClassificationButton =
    !isFilesProcessing && selectedFiles.length > 1

  if (!isToolbarVisible) {
    return null
  }

  return (
    <Toolbelt
      className={cn(
        'flex-col space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0',
        className
      )}
    >
      <div className="flex items-center">
        <ToolbeltButton className="truncate rounded-l-md rounded-r-none border border-r-0 border-dotted border-primary transition hover:bg-interactive">
          {[displayText, displayTextForReadyToQueryFiles]
            .filter(Boolean)
            .join(DOT_SEPARATOR)}
        </ToolbeltButton>
        <ToolbeltButton
          icon={X}
          className="rounded-l-none rounded-r-md border border-dotted border-primary"
          onClick={onDeselectAllRows}
        />
      </div>
      <ToolbeltDivider className="hidden md:block" />
      <div className="flex flex-wrap items-center justify-center gap-1 md:flex-nowrap md:gap-2">
        {shouldShowDocumentClassificationButton && (
          <VaultDocumentClassification
            fileIds={selectedFiles.map((file) => file.id)}
            pillClassName="text-primary text-sm bg-transparent"
          />
        )}
        {shouldShowRetryButton && (
          <ToolbeltButton
            data-testid="vault-file-explorer-toolbar-retry"
            icon={RotateCw}
            onClick={onRetrySelectedRows}
          >
            Retry
          </ToolbeltButton>
        )}
        {shouldShowMoveButton && (
          <ToolbeltButton
            data-testid="vault-file-explorer-toolbar-move"
            icon={FolderInput}
            onClick={onMoveSelectedRows}
          >
            Move
          </ToolbeltButton>
        )}
        {shouldShowDownloadButton && (
          <ToolbeltButton
            data-testid="vault-file-explorer-toolbar-download"
            icon={Download}
            onClick={onDownloadSelectedRows}
          >
            Download
          </ToolbeltButton>
        )}
        {shouldShowDeleteButton && (
          <ToolbeltButton
            data-testid="vault-file-explorer-toolbar-delete"
            icon={Trash}
            onClick={onDeleteSelectedRows}
          >
            Delete
          </ToolbeltButton>
        )}
      </div>
    </Toolbelt>
  )
}

export default VaultFileExplorerToolbelt
