import React from 'react'

import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { Maybe } from 'types'

import { Source } from 'utils/task'

import AssistantSources from 'components/assistant/components/assistant-sources'
import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
  AssistantWorkflowSourceExtractor,
} from 'components/assistant/workflows'
import { LoadingState } from 'components/assistant/workflows/components/loading-state/loading-state'
import RenderBlockToolbar from 'components/assistant/workflows/components/render-block-toolbar/render-block-toolbar'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'
import Markdown from 'components/common/markdown/markdown'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowAnswerRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams, loadingStates, paramStatus, feedback, stepId }) => {
  const content = blockParams.answer.response
  const { getHrvyInfoMetadata, handleSourceClick } = useSourceUtils({
    sources: blockParams.answer.sources as Source[],
  })

  const Sidebar = blockParams.answer.sources.length ? (
    <AssistantSources
      allDocuments={[]}
      sources={blockParams.answer.sources as Maybe<Source[]>}
      isStreaming={blockParams.answer.progress !== 100}
      onSetActiveFileId={handleSourceClick}
      // 42px makes it align with the content of the block
      className="sticky left-0 top-[68px] ml-4 mt-[42px] sm:ml-4"
    />
  ) : undefined

  const isCompleted = paramStatus === 'COMPLETED'

  return (
    <AssistantWorkflowThreadBlock sidebar={Sidebar}>
      <AssistantWorkflowHarveyComponent>
        <LoadingState isCompleted={isCompleted} states={loadingStates} />
        {content.length > 0 && (
          <div className="mt-5">
            <Markdown
              content={content}
              getHrvyInfoMetadata={getHrvyInfoMetadata}
              width="100%"
            />
          </div>
        )}
        {isCompleted && (
          <div className="mt-6">
            <RenderBlockToolbar
              content={content}
              feedback={feedback}
              workflowStepId={stepId ?? ''}
            />
          </div>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowAnswerRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams, paramStatus }) => {
  const headerText = blockParams.answer.headerText
  const renderedText = paramStatus !== 'COMPLETED' ? 'Working…' : headerText

  return (
    <WorkflowInput>
      <WorkflowInputFooter footer={renderedText} />
    </WorkflowInput>
  )
}

export const AssistantWorkflowAnswerRendererExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = ({ blockParams }) => {
  const content = blockParams.answer.response
  return <Markdown content={content} />
}

export const answerSourceExtractor: AssistantWorkflowSourceExtractor<
  typeof WorkflowRenderComponentBlocks.ANSWER
> = (blockParams) => {
  return blockParams.answer.sources as Source[]
}
