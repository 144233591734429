import * as React from 'react'

import { EventKind } from 'openapi/models/EventKind'

import { AssistantLoadPromptButton } from 'components/assistant/components/assistant-load-prompt-button'
import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/text-area'

export interface TextInputProps {
  onSubmit: (value: string) => void
  onCancel?: () => void
  optional?: boolean
  placeholder?: string
  isCompleted?: boolean
  value: string
  onChange: (value: string) => void
  isLoading?: boolean
  className?: string
  autoFocus?: boolean
  isSubmitDisabled?: boolean
}

const TextInput: React.FC<TextInputProps> = ({
  autoFocus,
  onSubmit,
  onCancel,
  optional,
  placeholder,
  isCompleted,
  value,
  onChange,
  isLoading,
  isSubmitDisabled,
}) => {
  const [queryPreview, setQueryPreview] = React.useState<string | null>(null)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  const handleSubmit = () => {
    if (isLoading || (!value && !optional)) return
    onSubmit(value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.metaKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className="relative -m-[10px] items-center">
      <Textarea
        className="size-full max-h-80 resize-none border-none bg-transparent py-4 pb-12 pl-4 pr-20 focus-visible:ring-0 focus-visible:ring-offset-0"
        onChange={handleChange}
        placeholder={queryPreview || placeholder}
        value={queryPreview ? '' : value}
        onKeyDown={handleKeyDown}
        disabled={isCompleted || isLoading}
        rows={1}
        isFluid
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus ?? false}
      />
      <div className="absolute bottom-[10px] right-[10px] flex space-x-2">
        {onCancel && (
          <Button
            data-testid="cancel-button"
            variant="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={handleSubmit}
          disabled={
            isSubmitDisabled ||
            isCompleted ||
            isLoading ||
            (!optional && !value)
          }
          isLoading={isCompleted || isLoading}
        >
          {optional && !value ? 'Skip' : 'Send'}
        </Button>
      </div>
      <div className="absolute bottom-[10px] left-[10px]">
        <AssistantLoadPromptButton
          setQuery={onChange}
          setQueryPreview={setQueryPreview}
          eventKind={EventKind.ASSISTANT}
          disabled={false}
        />
      </div>
    </div>
  )
}

export default TextInput
