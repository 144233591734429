import { DateRange } from 'react-day-picker'

import _, { isEmpty } from 'lodash'

import { AuditLogType } from 'openapi/models/AuditLogType'
import { Industry } from 'openapi/models/Industry'
import {
  instanceOfIntegrationType,
  IntegrationType,
} from 'openapi/models/IntegrationType'
import { SharingSettings } from 'openapi/models/SharingSettings'
import { VaultSettings } from 'openapi/models/VaultSettings'
import { WorkspaceEyesOffTier } from 'openapi/models/WorkspaceEyesOffTier'
import { WorkspaceFeature } from 'openapi/models/WorkspaceFeature'
import Services from 'services'
import { RequestError } from 'services/backend/backend'
import { Maybe } from 'types'

import { Env } from 'utils/env'
import { SafeRecord } from 'utils/safe-types'
import { environment, site } from 'utils/server-data'
import { Site } from 'utils/site'
import { ToBackendKeys } from 'utils/utils'

import { StatusBarProps } from 'components/common/status-bar'

// Try to keep most of the model's in sync with corresponding models in backend for ease.
// ref. models/workspace.py

export enum SensitiveFields {
  QUERY = 'query', // user query
  RESPONSE = 'response', // user facing response
  PROMPT = 'prompt', // raw prompt to model
  RAW_COMPLETION = 'raw_completion', // raw response from model
  SOURCES = 'sources', // citations if any
  APPENDIX_SOURCES = 'appendix_sources', // additional sources that come after the primary citations
  FEEDBACK_COMMENTS = 'feedback_comments', // user feedback comments

  // Claude specific fields
  CLAUDE_SUMMARY_OUTPUT = 'claude_summary_output', // claude summary output

  // SEC Edgar specific fields
  DECOMP_FUNCTION_ARGS = 'decomp_function_args',
  DECOMP_PEER_PROMPT = 'decomp_peer_prompt',
  DECOMP_PEER_FUNC_ARGS = 'decomp_peer_function_args',
  DECOMP_PEER_FALLBACK_PROMPT = 'decomp_peer_fallback_prompt',
  DECOMP_PEER_FALLBACK_COMPLETION = 'decomp_peer_fallback_completion',
}

export enum SensitiveFileFields {
  DOCUMENTS = 'documents',
}

export enum WelcomeScreenType {
  INTERSTITIAL = 'INTERSTITIAL',
  HELP_PANEL = 'HELP_PANEL',
  CLIENT_MATTER_HELP_TEXT = 'CLIENT_MATTER_HELP_TEXT',
  CLIENT_MATTER_DISALLOWED_TEXT = 'CLIENT_MATTER_DISALLOWED_TEXT',
  DISALLOWED_CLIENT_MATTER_INTERSTITIAL = 'DISALLOWED_CLIENT_MATTER_INTERSTITIAL',
}

export interface WelcomeScreen {
  type: WelcomeScreenType
  id: string
  content: string
  title?: string
  taskType?: string
}

export enum WorkspaceKind {
  ORGANIZATION = 'ORGANIZATION',
  TERRITORY = 'TERRITORY',
  PROJECT = 'PROJECT',
}

export interface StorageRegions {
  defaultRegion: string
  regions: string[]
}

export const DEFAULT_VAULT_PROJECTS_COUNT_LIMIT = 5
export const DEFAULT_VAULT_FILES_COUNT_LIMIT = 10_000
export const DEFAULT_VAULT_QUESTIONS_COUNT_LIMIT = 50

export const eyesOffTierToReadableName = {
  [WorkspaceEyesOffTier.EYES_OFFSTRICT]: 'Strict',
  [WorkspaceEyesOffTier.EYES_OFFBASIC]: 'Basic',
  [WorkspaceEyesOffTier.EYES_OFFDISABLED]: 'Disabled',
}

export interface RawWorkspace {
  slug: string
  id: number
  friendlyName?: string
  clientName: string
  domains: string[]
  kind: WorkspaceKind
  eyesOffTier: WorkspaceEyesOffTier
  settings?: Record<string, any>
  storageAccount?: string
  bucket?: string
  parentId?: number
  serviceAccountEmail?: string
  authConn?: string
  authConnGroupId?: string
  perms?: string[]
  deletedAt?: string
  createdAt?: string
  updatedAt?: string
  allowedCidrs?: string[]
  loginSlug?: string
}

export class Workspace {
  slug: string
  id: number
  friendlyName?: string
  clientName: string
  domains: string[]
  kind: WorkspaceKind
  eyesOffTier: WorkspaceEyesOffTier
  settings: Record<string, any>
  storageAccount?: string
  bucket?: string
  parentId?: number
  serviceAccountEmail?: string
  authConn?: string
  authConnGroupId?: string
  perms?: string[]
  deletedAt?: string
  allowedCidrs?: string[]
  createdAt?: string
  updatedAt?: string
  loginSlug?: string

  constructor(raw: RawWorkspace) {
    this.slug = raw.slug
    this.id = raw.id
    this.friendlyName = raw.friendlyName
    this.clientName = raw.clientName
    this.domains = raw.domains ?? []
    this.kind = raw.kind ?? WorkspaceKind.ORGANIZATION
    this.settings = raw.settings ?? {}
    this.storageAccount = raw.storageAccount
    this.bucket = raw.bucket
    this.parentId = raw.parentId
    this.serviceAccountEmail = raw.serviceAccountEmail
    this.authConn = raw.authConn
    this.authConnGroupId = raw.authConnGroupId
    this.perms = raw.perms
    this.deletedAt = raw.deletedAt
    this.eyesOffTier = raw.eyesOffTier
    this.allowedCidrs = raw.allowedCidrs
    this.loginSlug = raw.loginSlug
    this.createdAt = raw.createdAt
    this.updatedAt = raw.updatedAt
  }

  get retentionPeriod(): number | undefined {
    return this.settings.retentionPeriod ?? null
  }

  get vaultUsersCountLimit(): number | null {
    return this.getVaultSettings().vault_users_count_limit ?? null
  }

  get vaultUserCountIsUnlimited(): boolean {
    return !this.vaultUsersCountLimit
  }

  get vaultEventsRetentionPeriod(): number | null {
    return this.getVaultSettings().vault_events_retention_period ?? null
  }

  get vaultProjectsRetentionPeriod(): number | null {
    return this.getVaultSettings().vault_projects_retention_period ?? null
  }

  get vaultProjectsRetentionOnUpdate(): boolean {
    return this.getVaultSettings().vault_projects_retention_on_update ?? false
  }

  getVaultFilesCountLimit(feature?: WorkspaceFeature): number {
    if (isEmpty(this.getVaultSettings(feature))) {
      return DEFAULT_VAULT_FILES_COUNT_LIMIT
    }
    return (
      this.getVaultSettings(feature).vault_files_count_limit_per_project ??
      DEFAULT_VAULT_FILES_COUNT_LIMIT
    )
  }

  get loginTimeout(): number | undefined {
    return this.settings.loginTimeout ?? undefined
  }

  get exportFeedback(): boolean {
    return this.settings.exportFeedback ?? false
  }

  get welcomeScreens(): WelcomeScreen[] {
    let screens = this.settings?.welcomeScreens
    if (screens instanceof Object) {
      screens = Object.entries(screens).map(([, v]) => v as WelcomeScreen)
    }
    return screens ?? []
  }

  get isEyesOff(): boolean {
    return this.eyesOffTier !== WorkspaceEyesOffTier.EYES_OFFDISABLED
  }

  get hasInterstitial(): boolean {
    return this.welcomeScreens.some(
      (x) => x.type === WelcomeScreenType.INTERSTITIAL
    )
  }

  get hasClientMatterHelpText(): boolean {
    return this.welcomeScreens.some(
      (x) => x.type === WelcomeScreenType.CLIENT_MATTER_HELP_TEXT
    )
  }

  get clientMatterHelpText(): Maybe<string> {
    return this.welcomeScreens.find(
      (x) => x.type === WelcomeScreenType.CLIENT_MATTER_HELP_TEXT
    )?.content
  }

  get clientMatterDisallowedText(): string {
    const storedText = this.welcomeScreens.find(
      (x) => x.type === WelcomeScreenType.CLIENT_MATTER_DISALLOWED_TEXT
    )?.content
    const defaultText =
      'You are not allowed to use this client matter in your query.'
    return storedText ?? defaultText
  }

  // When a workspace has no retentionPeriod or its retention period is greater than 0 it could have history.
  // NO_STORE customers have been given a retention period of 0
  get couldHistory(): boolean {
    return _.isNil(this.retentionPeriod) || this.retentionPeriod > 0
  }

  // defaults to true to mimic current saml user behavior
  get blockNonProvisionedSamlUsers(): boolean {
    return this.settings.blockNonProvisionedSamlUsers ?? false
  }

  get features(): WorkspaceFeature[] {
    return this.settings.features ?? []
  }

  get integrations(): IntegrationType[] {
    if (
      _.isNil(this.settings.integrations) ||
      !Array.isArray(this.settings.integrations)
    ) {
      return []
    }
    // string should be an IntegrationType, but it's not enforced in json schema
    return this.settings.integrations.filter(
      (integration: unknown): integration is IntegrationType =>
        instanceOfIntegrationType(integration)
    )
  }

  get hasVaultAddOn(): boolean {
    return this.features.includes(WorkspaceFeature.VAULT_ADD_ON)
  }

  get vaultSettings(): SafeRecord<WorkspaceFeature, VaultSettingsObject> {
    return this.settings.vaultSettings ?? {}
  }

  get vaultAddOnSettings(): VaultSettingsObject {
    return this.getVaultSettings(WorkspaceFeature.VAULT_ADD_ON)
  }

  getVaultSettings(feature?: WorkspaceFeature): VaultSettingsObject {
    if (!feature) {
      return ToBackendKeys(this.vaultSettings) ?? {}
    }
    // TODO: Need this double read before we deprecate vault-add-on vault settings
    return {
      ...(ToBackendKeys(this.vaultSettings) ?? {}),
      ...(ToBackendKeys(this.vaultSettings)[feature] ?? {}),
    }
  }

  get site(): Site {
    return this.settings.migratedToSite ?? site ?? Site.US
  }

  get sharingSettings(): SharingSettings {
    return {
      assistant: {
        enabled: this.settings.sharingSettings?.assistant?.enabled ?? false,
        workspaceLevel:
          this.settings.sharingSettings?.assistant?.workspaceLevel ?? false,
      },
      vault: {
        enabled: this.settings.sharingSettings?.vault?.enabled ?? false,
        workspaceLevel:
          this.settings.sharingSettings?.vault?.workspaceLevel ?? false,
      },
    }
  }

  getIntegrationResourceUrl(integrationType: IntegrationType): Maybe<string> {
    return this.settings.resourceUrls?.[integrationType]
  }

  get industry(): Industry | undefined {
    return this.settings.industry
  }

  getSynclyTenantUrl(): Maybe<string> {
    if (environment === Env.LOCAL) {
      return 'https://harvey-dev1.syncly.au/'
    }
    return this.settings.synclyTenantUrl
  }
}

export type VaultSettingsObject = SafeRecord<VaultSettings, number> & {
  vault_projects_retention_on_update?: boolean
}

export async function FetchUserWorkspaces(): Promise<Workspace[]> {
  const result = await Services.Backend.Get<RawWorkspace[]>(
    'dashboard/workspaces'
  )
  return (result ?? []).map((d: RawWorkspace): Workspace => {
    return new Workspace(d)
  })
}

export async function FetchWorkspaces(): Promise<Workspace[]> {
  const result = await Services.Backend.Get<RawWorkspace[]>(
    'internal_admin/workspaces'
  )
  return (result ?? []).map((d: RawWorkspace): Workspace => {
    return new Workspace(d)
  })
}

export async function FetchWorkspace(workspaceId: number): Promise<Workspace> {
  const result = await Services.Backend.Get<RawWorkspace[]>(
    `internal_admin/workspaces/${workspaceId}`
  )
  const workspacesRaw = result ?? []
  if (workspacesRaw.length === 0) {
    throw new Error(`Workspace ${workspaceId} not found`)
  }
  const workspaceRaw = workspacesRaw[0]
  return new Workspace(workspaceRaw)
}

export async function CreateWorkspace(
  workspace: Workspace,
  region: string,
  dataPrivacy: string
): Promise<number> {
  const result = await Services.Backend.Post(
    'internal_admin/workspaces',
    { ...workspace, region: region, data_privacy: dataPrivacy },
    { throwOnError: true }
  )
  interface CreateWorkspaceResult {
    id: number
  }
  return (result as CreateWorkspaceResult).id
}

export async function UpdateWorkspace(workspace: Workspace) {
  await Services.Backend.Patch(
    `internal_admin/workspaces/${workspace.id}`,
    workspace,
    { throwOnError: true }
  )
}

export async function MergeWorkspaces(
  sourceWorkspaceId: number,
  destWorkspaceId: number
): Promise<number> {
  interface MergeWorkspacesResult {
    workspaceId: number
  }
  const result = await Services.Backend.Post<MergeWorkspacesResult>(
    `internal_admin/workspaces/merge`,
    {
      source_workspace_id: sourceWorkspaceId,
      dest_workspace_id: destWorkspaceId,
    }
  )
  if (_.isNil(result) || (_.isObject(result) && _.isEmpty(result))) {
    throw new Error(`Failed to merge workspaces`)
  }
  return result.workspaceId
}

export interface MoveToWorkspaceResponse {
  usersMoved: string[]
  usersFailed: string[]
}

export async function MoveToWorkspace(
  workspaceId: number,
  emails: string[],
  moveWithPerms: boolean = false
): Promise<MoveToWorkspaceResponse> {
  return Services.Backend.Post(
    `internal_admin/workspaces/${workspaceId}/move`,
    {
      userEmails: emails,
      moveWithPerms,
    }
  )
}

export interface DeleteWorkspaceHistoryResponse {
  deletedCount: number
}

export async function DeleteAllWorkspaceHistory(
  workspaceId: number,
  deleteAllBeforeDateUtc: string,
  shouldResetUsage: boolean = false
): Promise<DeleteWorkspaceHistoryResponse> {
  return await Services.Backend.Delete<DeleteWorkspaceHistoryResponse>(
    `history/all`,
    {
      workspace_id: workspaceId,
      delete_all_before_date: deleteAllBeforeDateUtc,
      should_reset_usage: shouldResetUsage,
    },
    {
      throwOnError: true,
    }
  )
}

export async function DeleteWorkspaceHistoryBulk(
  eventIds: number[]
): Promise<boolean | RequestError> {
  return await Services.Backend.Delete(
    `history`,
    {
      event_ids: eventIds,
    },
    {
      throwOnError: true,
    }
  )
}

export const isHarveyWorkspace = (workspaceSlug: string): boolean => {
  return workspaceSlug.startsWith('harvey')
}

export const isPwcWorkspace = (workspaceSlug: string): boolean =>
  workspaceSlug.startsWith('pwc')

export const resetWorkspaceInterstitial = async (
  workspaceId: number
): Promise<{ updatedUsers: number }> => {
  return await Services.Backend.Post(
    'internal_admin/workspaces/reset-interstitial',
    {
      workspace: workspaceId,
    }
  )
}

export const resetWorkspaceProductTour = async (
  workspaceId: number
): Promise<{ updatedUsers: number }> => {
  const result = await Services.Backend.Patch<{ updatedUsers: number }>(
    'internal_admin/workspaces/reset_product_tour',
    {
      workspace: workspaceId,
    }
  )
  if (result instanceof RequestError) {
    throw new Error(result.message)
  }
  return result
}

export type PermUserCountByWorkspace = {
  [key: number]: { [key: string]: number }
}
interface getWorkspacePermUserCountsResponse {
  permUserCountByWorkspace: PermUserCountByWorkspace
}

export const getWorkspacePermUserCounts = async (
  workspaceId: number
): Promise<PermUserCountByWorkspace> => {
  const resp: getWorkspacePermUserCountsResponse = await Services.Backend.Get(
    `internal_admin/workspaces/${workspaceId}/workspace_perm_user_counts`
  )
  return ToBackendKeys(resp.permUserCountByWorkspace)
}

export const setVaultEnablement = async (
  feature: WorkspaceFeature,
  workspaceId: number,
  enabled: boolean
): Promise<boolean> => {
  try {
    const resp = await Services.Backend.Post<boolean>(
      `internal_admin/workspaces/vault_enablement`,
      { workspaceId, enabled, feature },
      { throwOnError: true }
    )
    return resp
  } catch (e) {
    return false
  }
}

export const setVaultSettings = async (
  workspaceId: number,
  settings: Record<string, Maybe<number | boolean>>,
  feature: WorkspaceFeature
): Promise<boolean> => {
  return await Services.Backend.Post<boolean>(
    `internal_admin/workspaces/vault_settings`,
    { settings, workspaceId, feature },
    { throwOnError: true }
  )
}

export interface WorkspaceOffboardJob {
  jobStatus: string
  jobCreatedAt: string
  jobAttemptNext: string | null
  jobId: number
  jobType: string
}

export interface WorkspaceOffboardJobs {
  jobs: WorkspaceOffboardJob[]
}

export const InitWorkspaceOffboard = async (
  workspaceId: number,
  gracePeriodDays: number
): Promise<WorkspaceOffboardJob> => {
  return await Services.Backend.Post<WorkspaceOffboardJob>(
    `internal_admin/workspaces/${workspaceId}/offboard`,
    { grace_period_days: gracePeriodDays },
    { throwOnError: true }
  )
}

export const FetchWorkspaceOffboardJobs = async (
  workspaceId: number
): Promise<WorkspaceOffboardJobs> => {
  const resp = await Services.Backend.Get<WorkspaceOffboardJobs>(
    `internal_admin/workspaces/${workspaceId}/offboard`,
    { throwOnError: true }
  )
  return resp
}

export const CancelWorkspaceOffboard = async (
  workspaceId: number
): Promise<boolean> => {
  return await Services.Backend.Delete<boolean>(
    `internal_admin/workspaces/${workspaceId}/offboard`,
    {},
    { throwOnError: true }
  )
}

export const RescheduleWorkspaceOffboard = async (
  workspaceId: number,
  gracePeriodDays: number,
  jobId: number
): Promise<WorkspaceOffboardJob> => {
  const resp = await Services.Backend.Patch<WorkspaceOffboardJob>(
    `internal_admin/workspaces/${workspaceId}/offboard`,
    { updated_grace_period_days: gracePeriodDays, job_id: jobId },
    { throwOnError: true }
  )

  if (resp instanceof RequestError) {
    throw new Error(resp.message)
  }

  return resp
}

export const getOrgDomains = async (
  workspaceId: number
): Promise<{ domains: string[] }> => {
  const resp = await Services.Backend.Get<{ domains: string[] }>(
    `settings/${workspaceId}/org_domains`,
    { throwOnError: true }
  )
  return resp
}

export interface AuditLogEntry {
  id: number
  createdAt: string
  workspaceId: number
  userId: string
  timestamp: string
  logType: AuditLogType
  data: any
  email: string
}

export const getWorkspaceAuditLogs = async (
  workspaceId: number,
  dateRange: DateRange
): Promise<AuditLogEntry[]> => {
  const params = new URLSearchParams()
  if (dateRange.from)
    params.append('created_after', dateRange.from.toISOString())
  if (dateRange.to) params.append('created_before', dateRange.to.toISOString())

  const resp = await Services.Backend.Get<AuditLogEntry[]>(
    `internal_admin/workspaces/${workspaceId}/audit_logs?${params.toString()}`,
    { throwOnError: true }
  )
  return resp
}

interface SetStatusMessageProps {
  includedWorkspaces: number[]
  excludedWorkspaces: number[]
  statusBarMeta: StatusBarProps
  applyToAllWorkspaces: boolean
  name: string
}

interface SetStatusMessageResponse {
  workspacesStatusSet: string[]
  workspacesFailed: string[]
}

export const setStatusMessageForWorkspaces = async ({
  includedWorkspaces,
  excludedWorkspaces,
  statusBarMeta,
  applyToAllWorkspaces,
  name,
}: SetStatusMessageProps): Promise<SetStatusMessageResponse> => {
  return Services.Backend.Post<SetStatusMessageResponse>(
    'internal_admin/workspaces/status_message',
    {
      includedWorkspaces,
      excludedWorkspaces,
      statusBarMeta,
      applyToAllWorkspaces,
      name,
    },
    { throwOnError: true }
  )
}

export const setStatusMessageForWorkspace = async (
  workspaceId: number,
  statusBarMeta: StatusBarProps
): Promise<SetStatusMessageResponse> => {
  return Services.Backend.Post(
    `internal_admin/workspace/${workspaceId}/status_message`,
    { statusBarMeta },
    { throwOnError: true }
  )
}

interface StatusBarResponse {
  statusBarMeta: Maybe<StatusBarProps>
}

export const getStatusMessageForWorkspace = async (
  workspaceId: number
): Promise<Maybe<StatusBarResponse>> => {
  try {
    const resp = await Services.Backend.Get<StatusBarResponse>(
      `workspaces/${workspaceId}/status_message`,
      { throwOnError: true }
    )
    return resp
  } catch (e) {
    console.warn(
      `Error fetching status message for workspace ${workspaceId}`,
      e
    )
    return null
  }
}

// move this to openapi
export interface RawStatusMessage {
  id: string
  name: string
  messageType: string
  header: Maybe<string>
  description: string
  ctaText: Maybe<string>
  ctaLink: Maybe<string>
  variant: string
  workspaceId: number
  createdAt: string
  updatedAt: string
  deletedAt: Maybe<string>
}

interface GetAllStatusMessagesResponse {
  statusMessages: RawStatusMessage[]
}

export const getAllStatusMessages = async (): Promise<RawStatusMessage[]> => {
  const resp = await Services.Backend.Get<GetAllStatusMessagesResponse>(
    `internal_admin/workspaces/status_message`,
    { throwOnError: true }
  )
  return resp.statusMessages
}

export const deleteStatusMessageByName = async (
  name: string
): Promise<boolean> => {
  return Services.Backend.Delete<boolean>(
    `internal_admin/workspaces/status_message`,
    { name },
    { throwOnError: true }
  )
}

export const deleteStatusMessageByWorkspaceId = async (
  workspaceId: number
): Promise<boolean> => {
  return Services.Backend.Delete<boolean>(
    `internal_admin/workspaces/${workspaceId}/status_message`,
    { throwOnError: true }
  )
}

type IntegrationEnablementParams = {
  integration: IntegrationType
  enabled: boolean
  workspaceId: number
  resourceUrl?: Maybe<string>
  authToken?: Maybe<string>
}

export const setIntegrationEnablement = async ({
  integration,
  enabled,
  workspaceId,
  resourceUrl,
  authToken,
}: IntegrationEnablementParams): Promise<IntegrationType[]> => {
  if (enabled) {
    return Services.Backend.Post<IntegrationType[]>(
      `settings/integrations/${integration}/enable`,
      {
        workspaceId,
        ...(resourceUrl ? { resourceUrl } : {}),
        ...(authToken ? { authToken } : {}),
      },
      { throwOnError: true }
    )
  } else {
    return Services.Backend.Post<IntegrationType[]>(
      `settings/integrations/${integration}/disable`,
      { workspaceId, ...(authToken ? { authToken } : {}) },
      { throwOnError: true }
    )
  }
}

export const updateIntegrationResourceUrl = async (
  integration: IntegrationType,
  resourceUrl: string
): Promise<void> => {
  await Services.Backend.Patch<void>(
    `settings/integrations/${integration}/resource_url`,
    { resourceUrl },
    { throwOnError: true }
  )
}
