import React from 'react'

import { UploadedFile } from 'openapi/models/UploadedFile'
import { WorkflowRenderComponentBlocks } from 'openapi/models/WorkflowRenderComponentBlocks'
import { useFileCache } from 'stores/file-cache'

import { Source } from 'utils/task'

import {
  AssistantWorkflowComponent,
  AssistantWorkflowExportComponent,
  AssistantWorkflowSourceExtractor,
} from 'components/assistant/workflows'
import Summary from 'components/assistant/workflows/blocks/antitrust-filing-analysis/summary/summary'
import RenderBlockToolbar from 'components/assistant/workflows/components/render-block-toolbar/render-block-toolbar'
import WorkflowInput, {
  WorkflowInputFooter,
} from 'components/assistant/workflows/components/workflow-input/workflow-input'
import { useSourceUtils } from 'components/assistant/workflows/hooks/use-source-utils'

import {
  AssistantWorkflowThreadBlock,
  AssistantWorkflowHarveyComponent,
  AssistantWorkflowThreadText,
} from './assistant-workflow-block-layout'

export const AssistantWorkflowAntitrustFilingsAnswerRenderer: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER
> = ({ blockParams, completionStatus, paramStatus, feedback, stepId }) => {
  const content = blockParams.answer.metadata
  const text = blockParams.answer.response
  const addFile = useFileCache((state) => state.addFile)

  const { handleSourceClick } = useSourceUtils({ sources: [] })

  const internalHandleSourceClick = (source: Source) => {
    const sourceToUploadedFile = (source: Source): UploadedFile => {
      return {
        id: source.documentName!,
        name: source.documentName!,
        path: source.documentUrl,
        url: source.documentUrl,
      }
    }
    const uploadedFile = sourceToUploadedFile(source)
    addFile(uploadedFile)
    handleSourceClick(uploadedFile.id, source.id, [source])
  }

  return (
    <AssistantWorkflowThreadBlock>
      <AssistantWorkflowHarveyComponent>
        {text && (
          <AssistantWorkflowThreadText
            completionStatus={completionStatus}
            text={text}
          />
        )}
        {content && (
          <Summary
            handleSourceClick={internalHandleSourceClick}
            data={content}
          />
        )}
        {paramStatus === 'COMPLETED' && (
          <div className="mt-6">
            <RenderBlockToolbar
              content=""
              feedback={feedback}
              workflowStepId={stepId ?? ''}
            />
          </div>
        )}
      </AssistantWorkflowHarveyComponent>
    </AssistantWorkflowThreadBlock>
  )
}

export const AssistantWorkflowAntitrustFilingsAnswerRendererInputBox: AssistantWorkflowComponent<
  typeof WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER
> = ({ blockParams }) => {
  const headerText = blockParams.answer.headerText
  if (!headerText) {
    return null
  }

  return (
    <WorkflowInput>
      <WorkflowInputFooter footer={headerText} />
    </WorkflowInput>
  )
}

export const AssistantWorkflowAntitrustFilingsAnswerRendererExportComponent: AssistantWorkflowExportComponent<
  typeof WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER
> = ({ blockParams }) => {
  const content = blockParams.answer.metadata
  const text = blockParams.answer.response
  return (
    <>
      <div>{text}</div>
      {content && <Summary handleSourceClick={() => null} data={content} />}
    </>
  )
}

export const antitrustFilingsAnswerSourceExtractor: AssistantWorkflowSourceExtractor<
  typeof WorkflowRenderComponentBlocks.ANTITRUST_FILINGS_RENDER
> = (blockParams) => {
  return blockParams.answer.sources as Source[]
}
